@import '../../assets';

.background {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(33, 36, 43, 0.7);
  height: 100vh;
  width: 100%;
  z-index: 10;
  pointer-events: none;
}

.cancel_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 335px;
  padding: 23px 15px 20px;
  border-radius: 12px;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  z-index: 1000;

  &__top {
    margin-bottom: 20px;
    padding-bottom: 23px;
    border-bottom: 1px solid $gray_12;
  }

  &__center {
    margin-bottom: 40px;
    white-space: normal;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    padding: 0 32px;

    & > * {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  &__title {
    @extend %title-h3;
  }

  &__text {
    @include body();
    color: $black1 !important;
  }
}