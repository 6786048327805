@import '../../../../assets/';

.amount_settings_expire_input {
  width: 41.5%;
  height: 50px;
  padding: 14px 10px;
  margin-left: 23px;
  border-radius: 8px;
  border: 1px solid $gray_12;
  outline: none;

  &_error {
    width: 41.5%;
    height: 50px;
    padding: 14px 10px;
    margin-left: 23px;
    border-radius: 8px;
    border: 1px solid $red;
    outline: none;
  }

  &::placeholder {
    color: #D3D3D5;
  }

  &:focus {
    outline: none;
    border-color: $mainYellow !important;
  }
}

.amount_settings_reference_input {
  width: 41.5%;
  height: 50px;
  padding: 14px 10px;
  border-radius: 8px;
  border: 1px solid $gray_12;
  outline: none;

  &_error {
    width: 41.5%;
    height: 50px;
    padding: 14px 10px;
    border-radius: 8px;
    border: 1px solid $red;
    outline: none;
  }

  &::placeholder {
    color: #D3D3D5;
  }

  &:focus {
    border-color: $mainYellow !important;
  }
}
