@import '../../../assets/index.scss';

.container {
  position: fixed;
  height: fit-content;
  left: 50%;
  top: 50vh;
  text-align: center;
  transform: translate(-50%, -50%);
  background-color: $white;
  box-shadow: 0px 4px 10px rgba(33, 36, 43, 0.05), 0px 2px 30px rgba(237, 187, 31, 0.1);
  @include size(480px, 541px);
  padding: 38px 73px 18px;

  &__title {
    @extend %main-text-h4;
  }

  &__text_block {
    margin: 2px 35px 0;

    p {
      position: relative;
      @extend %grey-text;
    }

    img {
      position: absolute;
      margin-left: 5px;
    }
  }

  &__text {
    @extend %grey-text;
    margin-top: 8px;
  }

  &__banks {
    width: 216px;
    margin: 0 auto;
  }

  &__text_black {
    @extend %black-text;
    margin: 0 18px;
  }

  &__panel {
    margin: 38px 0 20px;
    display: flex;
    justify-content: center;
  }

  &__text_right {
    @extend %small-text;
    text-align: right;
    font-size: 14px;
  }

  &__grid{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: 22px 0 18px;
  }

  &__grid_cell{
    width: 39px;
    height: 39px;
    background-color: white;
    justify-content: center;
    display: flex;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
    img{
      margin: 0;
    }
  }

  &__button {
    padding: 0 28px;
    margin: 27px 0 40px 0;
  }

  &__button_details {
    padding: 0 28px;
    margin: 14px 0 40px 0;
  }

  &__footer{
    p {
     @extend %small-text;
    }
  }

  &__tip_block {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 130%;
    text-decoration: underline;
    margin-bottom: 10px;

    img {
      margin-right: 5px;
    }
  }

  &__details_block {
    padding: 20px 10px;
    margin: 20px 12px 20px 18px;
    text-align: left;
    border: 1px solid #707070;
    background: #FFFFFF;
    @include backgroundBlock($mainYellow, 7px);
  }
}
