@import '../../assets/index.scss';

.mobile_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 18%;

  &--with_default_padding {
    padding: 9.9% 8%;
  }

  &--with_small_padding {
    padding: 5.1% 4.8% 11.2%;
  }

  &--yellow.mobile_header--with_top_left_gradient {
    @include gradientBackground(-192deg, $mainYellow);
  }

  &--yellow.mobile_header--with_top_right_gradient {
    @include gradientBackground(192deg, $mainYellow);
  }

  &--green.mobile_header--with_top_left_gradient {
    @include gradientBackground(-192deg, $green_2);
  }

  &--green.mobile_header--with_top_right_gradient {
    @include gradientBackground(192deg, $green_2);
  }

  &--red.mobile_header--with_top_left_gradient {
    @include gradientBackground(-192deg, $red_2);
  }

  &--red.mobile_header--with_top_right_gradient {
    @include gradientBackground(192deg, $red_2);
  }

  &--with_menu {
    align-items: center;

    .mobile_header__right_icon_container {
      padding: 0;
    }
  }

  &__left_icon_container,
  &__right_icon_container {
    width: 8.53vw;
    height: 8.53vw;
    padding: 1.18%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__left_icon_container--for-menu {
    padding: 0;
  }

  &__title_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @extend %title-h2-mobile;
    text-align: center;
    padding: 0 8px;
  }

  &__logo {
    width: 23.7vw;
    height: 12.1vw;
    margin: 0 auto;
  }

  &__menu_container_bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100%;
    background-color: rgba($color: $mainBlack, $alpha: 0.85);
  }

  &__menu_container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 78vw;
    animation: menu_container_animation 0.8s;
    overflow: hidden;
  }

  &__colorful_ellipse {
    position: absolute;
    bottom: 0;
    right: 0;

    &--yellow {
      @include ellipseShadow($mainYellow);
    }

    &--red {
      @include ellipseShadow($ellipseRed);
    }

    &--green {
      @include ellipseShadow($ellipseGreen);
    }
  }
}

@keyframes menu_container_animation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
