@import '../../../assets/index.scss';

.container {
  height: 100%;

  &__center {
    height: 69.1%;
    padding: 0 5.3%;
    text-align: center;
  }

  &__top_icon {
    width: 17.1vw;
    height: 17.1vw;
  }

  &__title {
    @extend %main-text-h4-mobile;
    margin: 7.16% 0 2.99%;
  }

  &__link {
    @extend %black-text-mobile;
    display: inline-block;
    color: $lightPurple;
    text-decoration: underline;
    margin-top: 3%;
    margin-bottom: 8.95%;
  }

  &__text {
    @extend %light-dark-text-mobile;
  }

  &__bottom {
    height: 12.9%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      height: 8.1vh !important;
      width: 51.5%;
    }

    a {
      @extend %black-text-mobile;
      font-weight: 600 !important;
      display: inline-block;
      letter-spacing: 0.05em;
      margin-top: 8.8%;
    }
  }
}
