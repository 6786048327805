@import '../../assets/';

.datePicker {
  position: absolute;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 4px 4px;
  z-index: 1;

  &--fullWidth {
    width: 100%;
  }

  &__wrapper {
    position: relative;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 10px;
    border-bottom: 1px solid $gray_12;

    & > .datePicker__wrapper {
      max-width: 140px;
    }

    & > span {
      @include span;
      color: $gray;
      margin: 0 2px;
    }
  }

  &__date_preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 140px;
    padding: 16px 10px;
    border: 1px solid $gray_14;
    border-radius: 8px;
    cursor: pointer;

    img {
      height: 18px;
      width: 18px;
      margin-left: 10px;
    }

    &_value {
      @extend %black-text;
    }

    &:focus-within {
      outline: none;
      border-color: $mainYellow;
    }
  }

  &__button {
    @extend %black-text;
    font-weight: 700;
    margin-left: 10px;
    padding: 8px 15px;
    cursor: pointer;
  }

  &__list {
    padding-bottom: 6px;
  }

  &__listItem {
    @include main-tex-h5(400);
    display: block;
    padding: 11px 10px;
    cursor: pointer;

    &:hover {
      background-color: $mainBackground;
    }
  }
}
