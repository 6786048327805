@import '../../../assets/index.scss';

.add_bank_account {
  &__header {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(192deg, #FEE600 -4.4%, rgba(254, 230, 0, 0) 60%);
  }

  &__main {
    text-align: center;
    background-color: $white;
    background-color: transparent;
    padding: 0 5.34%;
  }

  &__title {
    @include family(bold);
    line-height: 128% !important;
    color: $mainBlack;
  }

  &__text {
    line-height: 130%;
    color: $gray_3;
  }

  &__banks {
    max-width: 64.5%;
    margin: 0 auto;
  }

  &__text_right {
    line-height: 130%;
    color: $gray_3;
    text-align: right;
  }

  &__grid {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    place-items: center;
  }

  &__grid_cell {
    background-color: white;
    justify-content: center;
    display: flex;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);

    img {
      margin: 0;
    }
  }

  &__button {
    button {
      width: 58%;
      height: 8.1vh !important;
    }
  }

  &__footer {
    padding: 0 5.34%;
    text-align: center;

    p {
      line-height: 130%;
      color: $lightDark;
      opacity: 0.6;
    }
  }

  &__tip_block {
    @include family();
    line-height: 130%;
    color: $mainBlack;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;

    img {
      margin-right: 1.5%;
    }
  }
}
