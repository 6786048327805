@import '../../../assets/index.scss';

$table_shrink_breakpoint: 1312px;
$table_resize_breakpoint: 1023px;
$small_table_breakpoint: 940px;

.keyManagement {
  position: relative;
  height: 100%;
  padding: 0 21px 75px;

  &__headerTop {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 20px;
    height: 14px;
    margin-right: 8px;
  }

  &__main {
    max-width: 1139px;
    margin-top: 20px;
  }
}

.tWrapper {
  padding: 24px 24px 30px 24px;

  &__name {
    @extend %title-h3;
    font-style: italic;
    line-height: 25px;
    margin-bottom: 32px;
  }

  &__table {
    width: 100%;
    table-layout: fixed;
    text-align: left;
  }

  thead {
    background: rgba($gray_6, 0.02);
  }

  &__title {
    @extend %black-text;
    font-style: italic;
    font-weight: normal;
    padding: 15px 0 17px;

    &:first-child {
      padding-left: 16px;
    }

    //name
    &:nth-child(1) {
      width: 21.8%;
    }

    //keys
    &:nth-child(2) {
      width: 35.5%;
    }

    //url
    &:nth-child(3) {
      width: 18%;
      padding-right: 83px;
    }

    //issued
    &:nth-child(4) {
      width: 13.3%;
    }

    //buttons
    &:nth-child(5) {
      width: 11.4%;
    }

    @media screen and (max-width: $table_shrink_breakpoint) {
      //url
      &:nth-child(3) {
        width: 0;
        overflow: hidden;
      }
    }

    @media screen and (max-width: $small_table_breakpoint) {
      @include span(bold);

      &:first-child {
        padding-left: 15px;
      }

      //name
      &:nth-child(1) {
        width: 34%;
      }

      //key
      &:nth-child(2) {
        width: 28%;
      }

      //issued
      &:nth-child(4) {
        width: 29%;
      }

      //buttons
      &:nth-child(5) {
        width: 9%;
      }
    }
  }

  &__tableFooter {
    @extend %api-key-table-gray-text;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 140% !important;
    position: relative;
    width: 100%;
    display: flex;
    background: #fbfbfc;
    min-height: 74px;
    align-items: center;
    justify-content: center;
    padding: 26px 5px;
    text-align: center;

    span {
      opacity: 0.7;
    }
  }

  &__button {
    font-weight: bold !important;
    font-size: 8px !important;
    padding: 2px 10px;
    height: 26px !important;
    width: 63px;

    &--small {
      height: 20px !important;
      width: 20px !important;
      padding: 0 !important;
      img {
        width: 15px;
        height: 15px;
      }
    }

    &--disabled {
      color: $yellow !important;
    }

    &--close {
      img {
        width: 6px;
        height: 6px;
      }
    }
    &--copy {
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 600 !important;
      text-transform: capitalize !important;
      padding: 2px 16px;
      width: 70px;
    }
  }

  &__keysAmount {
    @extend %api-key-table-gray-text;
    margin-top: 15px;
    font-size: 12px;
    font-style: italic;
  }
}

.tableRow {
  -webkit-box-shadow: 0px 1px 0px 0px rgba(151, 164, 186, 0.1);
  -moz-box-shadow: 0px 1px 0px 0px rgba(151, 164, 186, 0.1);
  box-shadow: 0px 1px 0px 0px rgba(151, 164, 186, 0.1);

  &__value {
    @extend %api-key-table-gray-text;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px 0;
    font-style: italic;

    &:first-child {
      @extend %black-text;
      padding-left: 16px;
    }

    //name
    &:nth-child(1) {
      span {
        width: 39%;
      }
      button {
        margin-left: 16.7%;
      }
    }

    //keys
    &:nth-child(2) {
      span {
        width: 74%;
      }
      button {
        margin-left: 12px;
        margin-right: 19%;
      }
    }

    //url
    &:nth-child(3) {
      span {
        width: 85%;
      }
    }

    //issued
    &:nth-child(4) {
      span {
        width: 85%;
      }
    }

    //buttons
    &:nth-child(5) {
      button:first-child {
        margin-right: 16%;
      }
    }

    @media screen and (max-width: $table_shrink_breakpoint) {
      //buttons
      &:nth-child(5) {
        button:first-child {
          margin-right: 4%;
        }
      }
    }

    @media screen and (max-width: $small_table_breakpoint) {
      font-style: normal;
      font-size: 13px;

      &:first-child {
        padding-left: 15px;
      }

      //name
      &:nth-child(1) {
        button {
          display: none;
        }
      }

      //keys
      &:nth-child(2) {
        button {
          margin-left: 4px;
        }
      }

      span {
        width: 90% !important;
      }

      //buttons
      &:nth-child(5) {
        button:first-child {
          display: none;
        }
      }
    }
  }

  &__value-wrap {
    display: flex;
    align-items: center;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.api-key-form-container-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $mainBlack;
  opacity: 0.85;
}

.api-key-form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;

  &__inner {
    padding: 69px 90px 30px 90px;
  }
}
