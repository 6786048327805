.api-key-form {
  form {
    display: grid;

    div + div {
      margin-top: 19px;
    }

    button {
      margin: 0 auto;
      margin-top: 27px;
      width: 93%;
    }
  }
}

.api-key-form-mobile {
  form {
    div {
      width: 90%;
      margin: 0 auto;
    }

    div + div {
      margin-top: 2.5vh;
    }

    button {
      margin-top: 4vh;
      height: 8.4vh !important;
    }

    input {
      height: 8vh;
    }
  }
}