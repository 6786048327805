$mainBlack: #21242b;
$mainYellow: #fee600;
$mainBackground: #f5f7fa;

$textFieldBase: #444951;
$background: #f5f6f8;
$background_2: #d6dae2;
$border: #dfe0eb;
$ellipseRed: #fc7a88;
$ellipseGreen: #adfc95;

$lightDark: #3c434d;
$lightRed: #fe776e;
$lightPurple: #9388e7;
$lightGray: #8a8e94;
$grayAa: #767676;

$green: #29cf75;
$green_2: #88ff62;
$yellow: #ffd602;
$orange: #edbb1f;
$white: #fff;
$gray: #97a4ba;
$gray_2: #c8c8c8;
$gray_3: #949494;
$gray_4: #989da3;
$gray_5: #bdbdbd;
$gray_6: #424b5f;
$gray_7: #fbfbfc;
$gray_8: #50565f;
$gray_9: #f9f9f9;
$gray_10: #4d4c4c;
$gray_11: #707070;
$gray_12: #EBEBEB;
$gray_13: #7A7C80;
$gray_14: #E9E9EA;
$gray_15: #909195;
$gray_17: #D3D3D5;
$gray_18: #F2F2F2;
$gray_19: #F4F4F4;

$black1: #333333;

$red: #fe655b;
$red_2: #ff4d5f;
$red_3: #FFF7F7;
