
@import '../../assets/index.scss';

.policy_label {
  display: block;
  position: relative;
  padding-left: 2.8vh;
  @extend %main-text-small;

  p {
    @extend %black-text;
    font-size: 2vh;
  }

  span {
    @extend %black-text;
    font-size: 2vh;
  }
  .link {
    text-decoration: underline;
  }
}

$checkbox-size: 2.2vh;

.policy_checkbox {
  position: absolute;
  top: 0;
  left: 0;

  height: $checkbox-size;
  width: $checkbox-size;
  margin: 0;
  
  opacity: 0;
  cursor: pointer;
  z-index: 3;

  &:checked ~ .checkmark {
    background-color: $mainYellow;
    border: none;

    &:after{
      display: block;
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  width: $checkbox-size;
  height: $checkbox-size;

  border: 0.735417px solid #D3D3D5;
  box-sizing: border-box;


  &::after {
    content: "";
    position: absolute;
    display: none;
    width: $checkbox-size;
    height: $checkbox-size;
    background-image:    url(../../assets/images/check-icon-white.svg);
    background-size:     90%;
    background-repeat:   no-repeat;
    background-position: center center; 
  }
}