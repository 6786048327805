@import '../../assets/';

.QR_code_block {
  width: 220px;
  height: 220px;
  background-color: $green;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
