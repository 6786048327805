@import '../../assets';

.cancel_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 89.3vw;
  padding: 6.14% 4% 5.34%;
  border-radius: 12px;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &__top {
    margin-bottom: 6.57%;
    padding-bottom: 7.55%;
    border-bottom: 1px solid $gray_12;
  }

  &__center {
    margin-bottom: 13.13%;
    white-space: normal;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    padding: 0 10.5%;

    & > * {
      &:not(:first-child) {
        margin-top: 4.16%;
      }
    }
  }

  &__title {
    @extend %title-h3-mobile;
  }

  &__text {
    @include body-mobile();
    color: $black1 !important;
  }
}