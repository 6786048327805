@import '../../../assets/index.scss';

.homePage {
  overflow-y: scroll;

  &__header {
    height: 21.7vh !important;
  }

  &__main {
    padding: 0 4.3%;
    padding-bottom: 1.9%;
  }

  &__activity_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 6.4%;
    padding-right: 5%;
    margin-bottom: 10.6%;
  }

  &__block {
    position: relative;
    background-color: $white;
    border-radius: 8px;
    margin-bottom: 4.3%;
    border: 1px solid $border;

    &__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 2.6% 0;
      overflow: hidden;

      &_time {
        @extend %small-text-mobile;
        width: 33.5%;
      }

      &_name {
        @include span-mobile;
        width: 25.8%;
        margin: 0 3.2%;
      }

      &_url {
        @extend %grey-text-mobile;
        width: 40.7%;
      }

      &_time,
      &_name,
      &_url {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__link {
      @extend %span;
      letter-spacing: 0.05em;
      text-decoration: underline;
      cursor: pointer;
    }

    &:first-child {
      text-align: center;
      padding: 7% 6.5% 3.2% 6.5%;
    }

    &:nth-child(2) {
      padding: 7% 4.1% 9.6% 4.1%;
    }

    &:nth-child(3) {
      padding: 7% 9.4% 8.8% 9.4%;
    }

    &__account {
      margin-top: 11.6%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__wrapper {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &__logo {
        width: 10.5vw;
        height: 10.5vw;
        margin-right: 9.8%;
      }

      &__button {
        width: 76%;
        margin-top: 5.8%;
        button {
          @extend %grey-text-mobile;
        }
      }
    }

    iframe {
      height: 36vh;
      width: 100%;
      max-width: 298px;
      max-height: 231px;
      border: none;
    }

    //iPad
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      iframe {
        max-width: none;
        max-height: none;
      }
    }

    //Galaxy Fold
    @media screen and (max-width: 280px) {
      iframe {
        height: 27vh;
      }
    }
  }

  &__transparent_layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  &__manageAccountButton {
    height: 6.6vh !important;
  }
}
