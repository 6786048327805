@import '../../assets/index.scss';

.toast {
  position: fixed;
  bottom: 99px;
  left: 50%;
  transform: translateX(-50%) translateX(110px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 335px;
  padding: 13px;
  border-radius: 12px;
  background: rgba($mainBlack, 0.8); 
  box-shadow: 0px 1px 8px rgba(80, 86, 95, 0.5);

  &__icon {
    margin-right: 13px;
    width: 22px;
    height: 22px;
  }

  &__warning_icon {
    width: 24px;
    height: 24px;
  }

  &__title {
    @extend %toast-text;
  }

  &--mobile {
    position: fixed;
    left: 50%;
    bottom: 6%;
    transform: translateX(-50%);
    width: 89.33%;
    min-width: initial;
    padding: 1.95vh;

    .toast__icon {
      margin-right: 1.95vh;
      width: 3.3vh;
      height: 3.3vh;
    }

    .toast__warning_icon {
      width: 3.6vh;
      height: 3.6vh;
    }

    .toast__title {
      @extend %toast-text-mobile;
    }
  }
}
