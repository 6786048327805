@import '../../assets/index.scss';

.input_field {
  display: flex;
  flex-direction: column;
  @extend %grey-text;
  text-align: left;

  input {
    @include h4();
    padding: 0 0 7px;
    margin: 11px 0 0;
    background: transparent;
    border: none;
    border-bottom: 2px solid #e0e0e0;

    &:focus {
      outline: none;
    }
  }

  &--mobile {
    @extend %grey-text-mobile;

    input {
      @include h4-mobile();
      padding: 0 0 2.1%;
      margin: 3.3% 0 0;
    }
  }
}

input.input_field__error {
  border-bottom-color: $lightRed;
}
