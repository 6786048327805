@import '../../../assets/index.scss';

.upgradeNow {
  padding: 0 20px 113px 20px;
}

.choose_subscription_plan {
  position: absolute;
  top: 13%;
  left: 55%;
  transform: translateX(-50%);
  width: 90.89vmin;
  height: 76vmin;
  max-width: 60vw;
  max-height: 85.1vh;
  text-align: center;

  &__wrapper {
    height: 100%;
    width: 100%;
  }

  &__main {
    padding: 3.055vh 3.492% 1.3vh;
    height: 100%;
    width: 100%;
  }

  &__title {
    @extend %h2;
    font-size: 2.66vh !important;

    color: $lightDark;
    text-transform: uppercase;
  }

  &__cards {
    display: flex;
    //grid-auto-flow: column;
    //grid-template-columns: 32% 32% 32%;
    //grid-column-gap: 5.8%;
    margin-top: 1.389vh;
    margin-bottom: 2.222vh;
    height: 95%;
    width: 100%;
    align-items: center;
  }

  &__link {
    @extend %link;
    font-size: 2vh !important;
  }
}

.subscription_plan_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 0 2.87vh;
  height: 100%;
  flex: 1;
  border-radius: 12px;
  text-align: center;

  &__button {
    color: $mainBlack !important;
    margin-top: 5.11vh;
    border-radius: 8px;
    font-weight: 800 !important;
    font-size: 1.48vh !important;
    line-height: 137.5%;
    width: 66.7%;
    height: 5.22vh !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__icon {
    width:8.55vh;
    height: 8.55vh;
  }

  &__price {
    font-size: 5.56vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__type_price {
    font-size: 3.33vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__payment_options {
    @include family(normal, Nunito Sans);
    font-size: 2.22vh;
    line-height: 130%;
  }

  &__description {
    @extend %subscription-card-text;
    font-size: 1.5vh !important;
    text-align: center;
    opacity: 0.6;
    margin-top: 5vh;
    width: 63.8%;
  }

  &__title {
    @extend %subscription-card-title;
    margin-top: 3.24vh;
  }
}

.subscription_plan_dark_card {
  background-color: $mainBlack;
  box-shadow: 0px 4px 10px rgba(60, 67, 77, 0.05),
    0px 2px 30px rgba(60, 67, 77, 0.1);

  &__text {
    color: #ffffff;
  }

  &__button {
    background-color: $yellow !important;
  }
}

.subscription_plan_light_card {
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  .subscription_plan_card__title {
    margin-top: 2.69vh;
  }

  &__text {
    font-family: Mulish !important;
    color: $mainBlack;
  }

  &__description {
    font-family: 'Nunito Sans' !important;
    margin-top: 5vh;
  }

  &__button {
    margin-top: 3.71vh;
    border: 2px solid #21242b;
    background-color: $white !important;
  }
}



.most_popular_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 2.87vh;
  height: 105%;
  flex: 1;
  border-radius: 12px;
  text-align: center;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  z-index: 1;

  &__button {
    color: $mainBlack !important;
    margin-top: 5.11vh;
    border-radius: 8px;
    font-weight: 800 !important;
    font-size: 1.48vh !important;
    line-height: 137.5%;
    width: 66.7%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: $yellow !important;
  }

  &__header {
    width: 100%;
    height: 3.55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffd602;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    &__text {
      @extend %toast-text;
      color: #21242b;
    }
  }

  &__icon {
    width:8.55vh;
    height: 8.55vh;
  }

  &__separator {
    width:90%;
    height: 1px;
    background-color: #E9E9EA;
  }

  &__price {
    font-size: 5.56vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__type_price {
    font-size: 3.33vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__payment_options {
    @include family(normal, Nunito Sans);
    font-size: 2.22vh;
    line-height: 130%;
  }

  &__description {
    @extend %subscription-card-text;
    font-size: 1.5vh !important;
    text-align: center;
    opacity: 0.6;
    width: 63.8%;

    &__bold {
      font-weight:900;
    }
  }

  &__title {
    @extend %subscription-card-title;
    margin-top: 3.24vh;
  }
}

.free_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5vh 0 2.87vh;
  height: 100%;
  flex: 1;
  border-radius: 12px;
  text-align: center;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &__button {
    color: $mainBlack !important;
    margin-top: 5.11vh;
    border-radius: 8px;
    font-weight: 800 !important;
    font-size: 1.48vh !important;
    line-height: 137.5%;
    width: 66.7%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: $yellow !important;
  }

  &__icon {
    width:8.55vh;
    height: 8.55vh;
  }

  &__separator {
    width:90%;
    height: 1px;
    background-color: #E9E9EA;
  }

  &__price {
    font-size: 5.56vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__type_price {
    font-size: 3.33vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__payment_options {
    @include family(normal, Nunito Sans);
    font-size: 2.22vh;
    line-height: 130%;
  }

  &__description {
    @extend %subscription-card-text;
    font-size: 1.5vh !important;
    text-align: center;
    opacity: 0.6;
    width: 63.8%;

    &__bold {
      font-weight:900;
    }
  }

  &__title {
    @extend %subscription-card-title;
    margin-top: 3.24vh;
  }
}

.value_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5vh 0 2.87vh;
  height: 100%;
  flex: 1;
  border-radius: 12px;
  text-align: center;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &__button {
    color: $mainBlack !important;
    border-radius: 8px;
    font-weight: 800 !important;
    font-size: 1.48vh !important;
    line-height: 137.5%;
    width: 66.7%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: $yellow !important;
  }

  &__icon {
    width:8.55vh;
    height: 8.55vh;
  }

  &__separator {
    width:90%;
    height: 1px;
    background-color: #E9E9EA;
  }

  &__price {
    font-size: 5.56vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__type_price {
    font-size: 3.33vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__payment_options {
    @include family(normal, Nunito Sans);
    font-size: 2.22vh;
    line-height: 130%;
  }

  &__description {
    @extend %subscription-card-text;
    font-size: 1.5vh !important;
    text-align: center;
    opacity: 0.6;
    width: 63.8%;

    &__bold {
      font-weight:900;
    }
  }

  &__description_container {
    display: flex;
    flex-direction: column;
    width:90%;
    background-color:#F5F7FA; 
    border-radius: 5px;

    span {
      margin-top: 1vh;
      margin-bottom: 1vh;
    }
  }

  &__title {
    @extend %subscription-card-title;
    margin-top: 3.24vh;
  }
}