@import '../../../assets';

.request_activity {
  height: 100%;

  &__filters {
    padding: 0 5.334% 4%;

    &--opened {
      .request_activity__filters_bottom {
        display: block;
      }
    }
  }

  &__filters_top {
    display: flex;
    align-items: center;
  }

  &__filters_bottom {
    margin-top: 5.98%;
    display: none;
  }

  &__search_filter {
    flex: 1;
    margin-right: 3.586%;
  }

  &__date_filter {
    padding-top: 2.99%;
    margin-bottom: 5.98%;
    border-top: 1px solid rgba($gray, 0.15);
  }

  &__settings_icon {
    width: 4.8vh;
    height: 4.8vh;
    margin-left: auto;
  }

  &__pagination {
    margin-top: 6.67%;
    padding: 0 5.34%;
    padding-bottom: 7.47%;
  }
}

.table {
  position: relative;
  display: block;
  width: 100%;
  padding: 4.27% 2.67%;
  white-space: nowrap;

  table {
    width: 100%;
    text-align: left;
    table-layout: fixed;
    white-space: nowrap;
  }

  thead {
    tr {
      white-space: break-spaces;
    }
  }

  &__title {
    @extend %request-table-title-mobile;
    padding-bottom: 2.734%;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    
    &_inner {
      display: flex;
      align-items: center;

      &::after {
        width: 1.8vh;
        height: 1.8vh;
        content: '';
        display: inline-block;
        margin-bottom: -0.88%;
        margin-left: 0.53vw;
        background: center / cover no-repeat;
      }
      
      &--asc {
        &::after {
          transform: rotate(270deg);
          background-image: url(../../../assets/images/backspace-arrow-16px.svg);
        }
      }
      
      &--desc {
        &::after {
          transform: rotate(90deg);
          background-image: url(../../../assets/images/backspace-arrow-16px.svg);
        }
      }
  
      &--inactive {
        &::after {
          transform: rotate(90deg);
          background-image: url(../../../assets/images/backspace-arrow-disabled.svg);
        }
      }
    }

    //date
    &:first-child {
      width: 12.5%;
      padding-left: 2.734%;
    }

    //reference
    &:nth-child(2) {
      width: 22.3%;
    }

    //amount
    &:nth-child(3) {
      width: 20.6%;
    }

    //total
    &:last-child {
      width: 25.1%;
      padding-right: 2.734%;
    }
  }

  &__popup {
    position: fixed;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    padding: 4% 0;
    min-width: 89.6%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: rgba($mainBlack, 0.8);

    &_icon {
      width: 2.4vh;
      height: 2.4vh;
      margin-right: 2.98%;
    }

    &_title {
      @include main-tex-h5-mobile();
      color:  $white !important;
    }
  }
}


.table_row {
  position: relative;
  height: 7.5vh;

  &:nth-child(odd) {
    background-color: rgba($gray, 0.05);
  }

  &__icon {
    margin-right: 2.42%;
    margin-left: 4.83%;
    width: 6.4vw;
    height: 6.4vw;
  }

  &__value {
    @include span-mobile();
    color: rgba($mainBlack, 0.6) !important;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    &:not(:last-child) {
      padding-right: 5px;
    }

    //date
    &:first-child {
      width: 8.5%;
      padding-left: 2.82%;
    }

    //reference
    &:nth-child(2) {
      width: 13.3%;
    }

    //amount
    &:nth-child(3) {
      width: 18.6%;
    }

    //total
    &:last-child {
      width: 30.1%;
      padding-right: 2.82%;
    }
  }

  &__last_value {
    position: relative;
    overflow: visible;
  }

  &__last_value_text_container {
    overflow: hidden;
    padding-right: 3.6vh;
    text-overflow: ellipsis;
  }

  &__three_dots {
    position: absolute;
    right: 2.54%;
    top: 50%;
    transform: translateY(-50%);
    width: 3.6vh;
    height: 3.6vh;
    cursor: pointer;
  }

  &__popup {
    position: absolute;
    right: 9.2%;
    bottom: 26%;
    transform: translateY(100%);
    z-index: 1;
  }
}