@import '../../../assets/index';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  @include size(100%, 100%);
}

.data_container {
  padding: 30px 28px;
  background-color: $white;
  border-radius: 12px;
  @include size(425px, 350px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.steps_subscription_plan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 908px;

  &__main {
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    @extend %h2;
    color: $lightDark;
    font-size: 20px;
    line-height: 115.5%;
  }

  &__description {
    @extend %grey-text;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__ol {
    @extend %grey-text;
    padding: 0;
    font-size: 15px;
    line-height: 140%;
  }

  &__li {
    text-align: center;
    list-style-position: inside;
  }
}
