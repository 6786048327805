@import '../../assets/';

.react-calendar {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 310px;
  height: 311px;
  padding: 14px 10px 16px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  button {
    background-color: transparent;
    border: none;
  }

  &__navigation {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    &__label {
      @include body(700);
      display: inline;
    }

    &__prev-button,
    &__next-button {
      display: flex;
      align-items: center;

      span {
        @include main-tex-h5(400);
        text-decoration: underline;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }

    &__prev-button {
      img {
        margin-right: 4px;
      }
    }

    &__next-button {
      img {
        margin-left: 4px;
        transform: rotate(180deg);
      }
    }

    &__prev2-button,
    &__next2-button {
      display: none;
    }
  }

  &__viewContainer {
    display: flex;
    flex: 1;
  }

  &__month-view {
    display: flex;

    & > * {
      align-items: flex-start !important;

      & > * {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__month-view__weekdays {
    margin-bottom: 9px;

    &__weekday {
      text-align: center;

      abbr {
        @extend %daypicker_weekday;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

  &__month-view__days {
    width: 100%;
    height: 100%;
  }

  &__month-view__days__day {
    @include body(400);
    cursor: pointer;

    &--neighboringMonth {
      color: $gray_17;
    }

    &[disabled] {
      color: $gray_17;
      cursor: default;
    }

    &:not([disabled]):hover {
      background-color: $mainYellow;
      opacity: 0.3;
    }
  }

  &__tile {
    border: 1px solid transparent !important;
    border-radius: 50%;

    &--active {
      border-color: $mainBlack !important;
    }
  }
}
