@import "../../../assets";

.payment_request {
  padding: 0 20px 23px 20px;
  position: relative;
  overflow-y: auto;
  width: 100%;

  &_container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    height: 714px;
  }

  &_table {
    padding-top: 60px;
    width: 68%;
  }

  &_subtitle {
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    width: 66%;
    z-index: 1;
    top: 120px;
  }

  &_error {
    margin-bottom: 40px;
  }

  &_content {
    display: flex;
    justify-content: space-between;
  }

  &_settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 68.5%;
    height: 100%;
    overflow-y: auto;

    &_requestTo {
      background-color: $white;
      border-radius: 4px;
      height: 120px;
      padding: 20px 0px 17px 30px;
      margin-bottom: 25px;

      & > h5 {
        @include main-tex-h5(700);
      }
    }
  }

  &_review {
    width: 29.8%;
  }

  &__toast {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    margin-top: -3px;
    margin-left: 23px;
  }
}