@import '../../../assets/index.scss';

.container {
  @include size(480px, 541px);
  position: fixed;
  left: 50%;
  top: 107px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  box-shadow: 0px 4px 10px rgba(33, 36, 43, 0.05), 0px 2px 30px rgba(237, 187, 31, 0.1);

  &__top {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 38px;

    &--with_shadow {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%) rotate(-180deg);
        width: 100%;
        height: 10px;
        background: linear-gradient(180deg, rgba(33, 36, 43, 0) 0%, rgba(33, 36, 43, 0.1) 100%, rgba(33, 36, 43, 0.15) 100%);
      }
    }
  }

  &__title {
    @extend %main-text-h4;
    margin-bottom: 25px;
  }

  &__search_wrapper {
    position: relative;
    width: 337px;
    height: 50px;
    margin-bottom: 10px;
    border: 1px solid $gray_14;
    border-radius: 8px;

    &:focus-within {
      outline: none;
      border-color: $mainYellow;
    }

    &__search_request {
      height: 100%;

      img {
        position: absolute;
        left: 10px;
        top: 13px;
        height: 24px;
        width: 24px;
      }

      input {
        @extend %black-text;
        width: 100%;
        height: 100%;
        padding: 0 0 0 45px;
        border: none;
        outline: none;
        background-color: transparent;
      }
    }
  }

  &__bank_items {
    @include customScrrollbar();
    flex: 1;
    width: 100%;
    padding: 10px 73px;
    overflow-y: auto;

    & > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

