@import '../../../assets/index.scss';

.forgotten_img {
		width: 44.7vh;
		height: 33.6vh;
}

.connect_with_bank_img {
	width: 39.3vh;
	height: 38.4vh;
}

.account_not_found_img {
	width: 38.7vh;
	height: 38.7vh;
}

.top_text {
	position: absolute;
	top: -13%;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__title {
		margin-bottom: 1.9%;
		width: 82%;
		text-align: center;
		&--small {
			@extend %screen-small-title-mobile;
		}

		&--normal {
			@extend %h2-mobile;
		}
	}

	&__subtitle {
		@include body-mobile();
		opacity: 0.5;
		width: 82%;
		text-align: center;
	}
}

.container {
	height: 100%;

	&__center {
		position: relative;
		height: 60%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__footer {
		height: 22%;
		padding: 0 5.3%;
		text-align: center;

		button {
			margin-bottom: 4%;
			height: 7.5vh !important;
		}
	}

	&__footer_link {
		@extend %span-link-mobile;
	}
}

.login {
	height: 100%;

	&__main {
		position: relative;
		height: 82%;
	}

	&__main_content {
		height: 100%;
		padding-top: 3%;
	}

	&__forgot_pin_link {
		@extend %span-link-mobile;
	}

	&__new_account_link {
		@extend %span-link-mobile;
	}
}
