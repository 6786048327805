@import '../../../assets';

.header {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba($gray, 0.15);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
  }

  &__left_side {
    display: flex;
    align-items: center;
  }

  &__back_button {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  &__back_button_splitter {
    width: 1px;
    height: 35px;
    margin: 0 20px;
    background-color: rgba($gray, 0.15);
  }

  &__title {
    @extend %title-h3;
  }

  &__CTA_button,
  &__secondary_button {
    @include span(400);
    width: fit-content;
    padding: 8px 22px;
    border-radius: 60px !important;
  }

  &__CTA_button:nth-child(1) {
    margin-left: 10px;
  }
}