@import '../../../assets/';

.payment_request {
  &__bank_logo_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.5vh;
    height: 7.5vh;
    margin-right: 3.59%;
    border: 0.5px solid #BCBDBF;
    border-radius: 100%;

    img {
      width: 7.5vh;
      height: 7.5vh;
    }
  }

  &__error {
    position: relative;

    &_border {
      position: absolute;
      left: 50%;
      top: -17.37%;
      transform: translate(-50%, 0);
      width: 89.3%;
      height: 1px;
      background-color: rgba($gray, 0.15);
    }
  }

  &__bank {
    position: relative;
    margin-bottom: 6.67%;
    padding: 5.334% 5.334% 4.534%;
    background-color: $white;

    &_border {
      position: absolute;
      left: 50%;
      top: -17.37%;
      transform: translate(-50%, 0);
      width: 89.3%;
      height: 1px;
      background-color: rgba($gray, 0.15);
    }

    &_label {
      @include main-tex-h5-mobile(700);
    }

    &_info {
      display: flex;
      align-items: center;
      margin-top: 4.48%;
    }

    &_logo {
      height: 7.5vh;
      width: 7.5vh;
      margin-right: 3.586%;
    }

    &_names {
      h3 {
        @extend %title-h3-mobile;
        margin-bottom: 1.47%;
      }

      span {
        @include main-tex-h5-mobile();
        color: $gray_13 !important;
      }
    }
  }

  &__settings_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4%;
    padding: 0 3.2% 0 5.334%;

    button {
      @extend %button-10px-mobile;
      flex: 1;
      width: fit-content;
      max-height: 7.5vh !important;
      padding-left: 4.374%;
      padding-right: 4.374%;

      &:not(:first-child) {
        margin-left: 1.46%;
      }
    }
  }

  &__settings_fields {
    padding: 8% 5.334%;
    border-radius: 4px;
    background-color: $white;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5.334%;
    padding: 0 5.334% 3.47%;

    &_button {
      @include span-mobile(400);
      width: fit-content;
      height: 6vh !important;
      min-width: 40vw;
      margin-bottom: 5.334%;
      padding: 0 6.87%;
    }

    &_info {
      display: flex;
      width: 100%;

      &_icon {
        height: 2.1vh;
        width: 2.1vh;
        margin-right: 1.6%;
      }

      &_text {
        @include span-mobile();
      }

      &_link {
        @include span-mobile();
        text-decoration: underline;
      }
    }
  }

  &__input--error {
    border-color: $red !important;
  }

  &__toast {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    width: 100%;
    margin-top: 1.8vh;
  }
}

.amount_settings {
  &:nth-child(2) {
    margin-top: 8.956%;
  }

  &_header {
    @include main-tex-h5-mobile(700);
    margin-bottom: 4.48%;
  }

  &_input {
    @extend %black-text-mobile;
    margin-left: 1.599%;
    border-color: transparent;
    width: 90%;
    outline: none;
  }

  &_certain_date {
    margin-top: -1.497%;
    padding-top: 4.48%;
    border-top: 1px solid rgba(151, 164, 186, 0.15);
  }

  &_date_input {
    @extend %black-text-mobile;
    height: 7.5vh;
    width: 93.14%;
    margin-left: 6.87%;
    margin-top: 2.989%;
    padding: 4.78% 2.989%;
    border-radius: 8px;
    border: 1px solid $gray_14;
    background-color: $white;
    outline: none;
  }

  &_reference_input {
    @extend %black-text-mobile;
    height: 7.5vh;
    padding: 4.78% 2.989%;
    border-radius: 8px;
    border: 1px solid $gray_14;
    outline: none;

    &::placeholder {
      color: #D3D3D5;
    }
  }

  &_thank_you_note_input {
    width: 93%;
  }

  &_expire_input {
    @extend %black-text-mobile;
    height: 7.5vh;
    width: 91.93%;
    padding: 4.913% 3.51%;
    border-radius: 8px;
    margin-left: 6.87%;
    margin-top: -1.756%;
    border: 1px solid $gray_14;
    outline: none;

    &::placeholder {
      color: #D3D3D5;
    }
  }
}
