@import "../../assets/index.scss";

.modalWrapper {
  width: 310px;
  height: 127px;
  background-color: #4D5055;
  border-radius: 12px 0 12px 12px;
  color: white;
  padding: 15px 20px;
}

.modalWrapper:before {
  content: '';
  width: 0;
  height: 0;
  border: 11px solid transparent;
  border-right: 11px solid #4D5055;
  border-bottom: 11px solid #4D5055;
  top: -19px;
  right: 0;
  position: absolute;
}

.titleWrapper {
  @extend %fee-info-modal-title;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.textWrapper {
  @extend %fee-info-modal-text;
  height: 60%;
  white-space: pre-line;
}
