@import '../../../assets/index.scss';

.container {
  position: fixed;
  height: fit-content;
  left: 50%;
  top: 50vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  transform: translate(-50%, -50%);
  background-color: $white;
  box-shadow: 0px 4px 10px rgba(33, 36, 43, 0.05),
    0px 2px 30px rgba(237, 187, 31, 0.1);
  @include size(480px, 541px);
  padding: 38px 73px 30px;

  &__title {
    @extend %main-text-h4;
  }

  &__text {
    @extend %light-dark-text;
    margin-bottom: 30px;
    margin-top: 10px;
    opacity: 0.6;
  }

  &__button {
    width: 85%;
    margin: 0 auto;
  }
}
