@import '../../assets/index.scss';

.bank_item {
  height: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  border: 1px solid $gray_18;
  border-radius: 30px;
  background: $white;
  cursor: pointer;

  &__icon {
    background: $mainBlack;
    display: flex;
    width: 50px;
    height: 50px;
    padding: 8px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    img {
      width: 34px;
      height: auto;
    }
  }

  &__background_white {
    background: $white;
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 0;

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }

  &__account {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #21242B;
    padding: 0 10px;

    &__name {
      @include h4(700);
      text-align: left;
    }
  }
}

.bank_item__account__red_text{
  @extend %grey-text;
  font-weight: normal;
  color: $red;
  display: block;
}

.bank_item__grey {
  .bank_item__account__name {
    color: $gray_5;
  }
}