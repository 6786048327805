@import '../../assets/index.scss';

%small-border {
  box-shadow: 0px 0px 0px 1px rgba(147,136,231,1);
  -moz-box-shadow: 0px 0px 0px 1px rgba(147,136,231,1);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(147,136,231,1);
}

.panel {
  display: flex;
  justify-content: space-between;

  &__image {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-right: 8px;
    @include size(50px);
    background: #fff;
    border-radius: 50%;

    &_logo {
      max-width: 50px;
      max-height: 50px;
      border-radius: 50%;
    }

    &_border {
      @extend %small-border;
    }
  }

  .spinner {
    @include size(40px);
  }

  &__name {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 220px;
    background: #fff;
    @extend %small-border;
    border-radius: 25px;
    cursor: pointer;

    &_title {
      @extend %main-text-h4;
    }
  }
}
