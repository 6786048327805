@import '../../../../../assets/';

.input {
  &--error {
    border-color: $red !important;
  }

  &__amount_settings_with_currency {
    width: 41.5%;
    height: 50px;
    padding: 14px 10px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid $gray_12;

    & > span {
      @extend %black-text;
    }

    &:focus-within {
      outline: none;
      border: 1px solid $mainYellow !important;
    }
  }

  &__amount_settings_input_requirement {
    margin: 2px 0 15px;
    @extend %subscription-card-text;
    color: $gray_3;
    font-weight: lighter;
  }

  &__amount_settings_input_requirement_other_setting {
    display: block;
    margin: 3px 0 15px 23px;
    @extend %subscription-card-text;
    color: $gray_3;
    font-weight: lighter;
  }

  &__expiry_date_input {
    position: relative;
    padding: 0;

    &_inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 14px 10px;

      &:focus-within  {
        border: 1px solid $mainYellow !important;
        border-radius: 8px;
        outline: none;
      }
    }

    &_value {
      @extend %black-text;
    }

    &_icon {
      width: 18px;
      height: 18px;
    }

    &_custom_calendar {
      right: 0;
      bottom: 0;
      transform: translateY(100%);
    }
  }
}
