@import '../../../assets/index.scss';

.container {
  height: 100%;

  &__center {
    height: 69.1%;
    padding: 0 5.34%;
    text-align: center;
  }

  &__bottom {
    display: flex;
    justify-content: center;
    height: 12.9%;

    button {
      height: 8.1vh !important;
      width: 51.5%;
    }
  }

  &__title {
    @extend %main-text-h4-mobile;
  }

  &__text {
    @extend %light-dark-text-mobile;
    margin-top: 3%;
    margin-bottom: 9%;
  }
}
