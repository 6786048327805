@import '../../../assets/index.scss';

.choose_subscription_plan {
  height: 100%;

  &__top {
    transform: translateY(-59.9%);
    padding: 0 19.2%;
  }

  &__center {
    width: 100%;
    padding: 0 5.3%;
  }

  &__bottom {
    padding: 5.34%;
    text-align: center;
  }

  &__title {
    @include h4-mobile(600);
    color: $lightDark;
    text-align: center;
  }

  &__link {
    @include span-mobile();
  }
}

.subscription_plan_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.46% 5.97% 4.77%;
  border-radius: 12px;
  text-align: center;

  & + & {
    margin-top: 5.97%;
  }

  &__button {
    height: 8.4vh !important;
    color: $mainBlack !important;
    font-weight: 800 !important;
    font-size: 2.4vh !important;
    line-height: 137%;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 9.15%;
    border-radius: 8px;
  }

  &__icon {
    width: 10.5vh;
    height: 10.5vh;
  }

  &__price {
    font-size: 7.5vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__type_price {
    font-size: 4.5vh;
    letter-spacing: 0.05em;

    &:first-child {
      margin-right: 3px;
    }

    &:last-child {
      margin-left: 3px;
    }
  }

  &__payment_options {
    @include family(normal, Nunito Sans);
    font-size: 2.7vh;
    line-height: 130%;
  }

  &__description {
    @extend %subscription-card-text-mobile;
    opacity: 0.6;
    text-align: center;
    margin-top: 5.42%;
  }

  &__title {
    @extend %subscription-card-title-mobile;
    margin-top: 10.17%;
  }
}

.subscription_plan_dark_card {
  background-color: $mainBlack;

  &__text {
    color: $white;
  }

  &__button {
    background-color: $yellow !important;
  }
}

.subscription_plan_light_card {
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  .subscription_plan_card__title {
    margin-top: 6.78%;
  }

  &__text {
    font-family: Mulish !important;
    color: $mainBlack;
  }

  &__description {
    font-family: 'Nunito Sans' !important;
    color: $textFieldBase;
  }

  &__button {
    border: 2px solid $mainBlack;
    background-color: $white !important;
  }
}

.most_popular_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 2.87vh;
  height: 105%;
  flex: 1;
  border-radius: 12px;
  text-align: center;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  z-index: 1;
  margin-top: 2vh;
  margin-bottom: 2vh;

  &__button {
    color: $mainBlack !important;
    margin-top: 5.11vh;
    border-radius: 8px;
    font-weight: 800 !important;
    font-size: 1.48vh !important;
    line-height: 137.5%;
    width: 66.7%;
    height: 6.22vh !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: $yellow !important;
  }

  &__header {
    width: 100%;
    height: 3.55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffd602;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-bottom: 2vh;

    &__text {
      @extend %toast-text;
      color: #21242b;
    }
  }

  &__icon {
    width:8.55vh;
    height: 8.55vh;
  }

  &__separator {
    width:90%;
    height: 1px;
    background-color: #E9E9EA;
  }

  &__price {
    font-size: 5.56vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__type_price {
    font-size: 3.33vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__payment_options {
    @include family(normal, Nunito Sans);
    font-size: 2.22vh;
    line-height: 130%;
  }

  &__description {
    @extend %subscription-card-text;
    font-size: 1.5vh !important;
    text-align: center;
    opacity: 0.6;
    width: 63.8%;

    &__bold {
      font-weight:900;
    }
  }

  &__title {
    @extend %subscription-card-title;
    margin-top: 3.24vh;
  }
}

.free_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5vh 0 2.87vh;
  height: 100%;
  flex: 1;
  border-radius: 12px;
  text-align: center;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &__button {
    color: $mainBlack !important;
    margin-top: 5.11vh;
    border-radius: 8px;
    font-weight: 800 !important;
    font-size: 1.48vh !important;
    line-height: 137.5%;
    width: 66.7%;
    height: 6.22vh !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: $yellow !important;
  }

  &__icon {
    width:8.55vh;
    height: 8.55vh;
  }

  &__separator {
    width:90%;
    height: 1px;
    background-color: #E9E9EA;
  }

  &__price {
    font-size: 5.56vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__type_price {
    font-size: 3.33vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__payment_options {
    @include family(normal, Nunito Sans);
    font-size: 2.22vh;
    line-height: 130%;
  }

  &__description {
    @extend %subscription-card-text;
    font-size: 1.5vh !important;
    text-align: center;
    opacity: 0.6;
    width: 63.8%;

    &__bold {
      font-weight:900;
    }
  }

  &__title {
    @extend %subscription-card-title;
    margin-top: 3.24vh;
  }
}

.value_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5vh 0 2.87vh;
  height: 100%;
  flex: 1;
  border-radius: 12px;
  text-align: center;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &__button {
    color: $mainBlack !important;
    border-radius: 8px;
    font-weight: 800 !important;
    font-size: 1.48vh !important;
    line-height: 137.5%;
    width: 66.7%;
    height: 6.22vh !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: $yellow !important;
  }

  &__icon {
    width:8.55vh;
    height: 8.55vh;
  }

  &__separator {
    width:90%;
    height: 1px;
    background-color: #E9E9EA;
  }

  &__price {
    font-size: 5.56vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__type_price {
    font-size: 3.33vh;
    line-height: 130%;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  &__payment_options {
    @include family(normal, Nunito Sans);
    font-size: 2.22vh;
    line-height: 130%;
  }

  &__description {
    @extend %subscription-card-text;
    font-size: 1.5vh !important;
    text-align: center;
    opacity: 0.6;
    width: 63.8%;

    &__bold {
      font-weight:900;
    }
  }

  &__description_container {
    display: flex;
    flex-direction: column;
    width:90%;
    background-color:#F5F7FA; 
    border-radius: 5px;

    span {
      margin-top: 1vh;
      margin-bottom: 1vh;
    }
  }

  &__title {
    @extend %subscription-card-title;
    margin-top: 3.24vh;
  }
}