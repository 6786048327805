@import "../../assets";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba($black1, 0.9);
  overflow-y: auto;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4.27%;
    padding: 6.134% 0 8%;
    width: 89.3%;
    height: fit-content;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  }

  &__top {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 5.34vw;

    &:after {
      content: '';
      position: absolute;
      left: 0;      
      bottom: 0;
      width: 100%;
      height: 3vh;
      background: linear-gradient(180deg, rgba(33, 36, 43, 0) 0%, rgba(33, 36, 43, 0.1) 100%, rgba(33, 36, 43, 0.15) 100%);
      transform: rotate(-180deg) translateY(-100%);
    }
  }

  &__title {
    @include body-mobile(700);
    margin-bottom: 8.82%;
  }

  &__transaction_icon {
    height: 7.5vh;
    width: 7.5vh;
    margin-bottom: 5.09%;
  }

  &__amount_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.396%;
  }

  &__amount {
    @extend %payment-details-details-amount-mobile;
    margin-bottom: 3.99%;
  }

  &__gift_aid_top_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -0.75vh;
  }

  &__gift_aid_top_info_title {
    @include main-tex-h5-mobile(400);
    color: $gray_13;
  }

  &__gift_aid_top_info_line {
    width: 26.13vw;
    height: 1px;
    margin: 1.5vh 0;
    background-color: $gray_12;
  }

  &__gift_aid_top_info_amount {
    @include h4-mobile(400);
    color: $black1;
  }

  &__reference {
    @include main-tex-h5-mobile();
    opacity: 0.6;
    margin-bottom: 6.783%;

    &--gift-aid {
      margin-bottom: 1.65vh;
    }
  }

  &__list {
    @include customScrrollbarMobile();
    width: 100%;
    margin-bottom: 3.3vh;
    max-height: 48.57vh;
    overflow-y: auto;
  }

  &__list_boolean_value {
    display: flex;
    align-items: center;

    img {
      width: 3.6vh;
      height: 3.6vh;
      margin-left: 11.7%;
    }
  }

  &__gift_aid_item {
    align-items: flex-start !important;
    height: fit-content !important;
    padding: 5.77% 0 5.43%;

    & > *:last-child {
      flex: 1;
    }
  }

  &__list_address_value {
    line-height: 140%;
    white-space: break-spaces;
  }

  &__list_gift_aid_value {
    width: 75.173%;
    margin-left: auto;
    text-align: right;
    line-height: 140%;
  }

  &__close_button_container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $white;

    &:before {
      content: '';
      position: absolute;
      left: 0;      
      top: -3.26vh;
      width: 100%;
      height: 3vh;
      background: linear-gradient(180deg, rgba(33, 36, 43, 0) 0%, rgba(33, 36, 43, 0.1) 100%, rgba(33, 36, 43, 0.15) 100%);
      transform: translateY(-100%);
    } 
  }

  &__close_button {
    font-size: 1.8vh !important;
    height: 6vh !important;
    width: 37.33vw;
  }
}

.list_item {
  display: flex;
  justify-content: space-between;
  padding: 2.55vh 0;
  margin: 0 5.34vw;
  border-top: 1px solid $gray_12;

  &:first-child,
  &:last-child {
    position: relative;
    z-index: 1;
    margin: 0;
    background-color: $white;

    .list_item__name {
      margin-left: 5.34vw;
    }

    .list_item__value {
      margin-right: 5.34vw;
    }
  }

  &:last-child {
    position: relative;
    border-top: none;
    border-bottom: 1px solid $gray_12;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      height: 1px;
      width: 78.62vw;
      background-color: $gray_12;
    }
  }

  &__name {
    @include span-mobile(700);
  }

  &__value {
    @include span-mobile();
    color: rgba($mainBlack, 0.6) !important;
    max-width: 50.4vw;
    text-align: right;
    overflow-wrap: break-word;
    white-space: break-spaces;
  }
}
