@import '../../../assets/index.scss';

.activity {
  &__title {
    @extend %title-h3
  }

  &__filter_container {
    display: flex;
    flex-direction: column;
    padding: 0 0 5.334%;
    margin: 0 5.334%;

    &_search {
      width: 85%;
    }

    &_date {
      margin-bottom: 5.99%;
    }

    &:not(.activity__filter_container--opened) {
      .activity__filter_container_bottom {
        display: none;
      }
    }

    .activity__filter_container_bottom {
      margin-top: 5.99%;
      padding-top: 2.99%;
      border-top: 1px solid rgba($gray, 0.15);
    }
  }

  &__filter_container_top {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__filter_container_icon {
    width: 4.8vh;
    height: 4.8vh;
    margin-left: 3.586%;
  }

  &__graph_container {
    margin: 0 5.34%;
  }

  &__table_container {
    margin-top: 5.4%;
    padding-bottom: 6.7%;
  }

  &__pagination_container {
    padding: 0 5.1%;
    padding-bottom: 7.5%;
  }
}

.graph {
  position: relative;

  & > :first-child {
    margin-bottom: 6%;
  }

  &__content {
    margin-top: 5.7%;
    margin-bottom: 9.7%;
  }

  &__title {
    @include h4-mobile;
    margin-bottom: 6.5%;
    text-align: center;
  }

  &__details {
    display: flex;
    flex-direction: column;
    @include body-mobile;
    text-align: center;

    & span:last-child {
      font-size: 1.6vh;
      line-height: 130%;
      letter-spacing: 0.04em;
      opacity: 0.6;
    }
  }

  &__amount {
    position: relative;
    @extend %total-amount-mobile;

    &:nth-child(2)::after {
      content: '';
      display: block;
      width: 100%;
      margin: 24.5% 0 18.9%;
      opacity: 0.2;
      border-bottom: 1px dashed $mainBlack;
    }
  }
}

.tWrapper {
  display: block;
  white-space: nowrap;

  &__tools {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 5.7%;
    background-color: $background;

    &--hiden {
      & > *:not(:first-child) {
        display: none;
      }
    }

    & > *:not(:first-child) {
      margin-top: 10%;
    }
  }

  &__toolsTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > *:first-child {
      width: 83.4%;
    }
  }

  &__filterMenuIcon {
    width: 4.8vh;
    height: 4.8vh;
  }

  &__amountRange {
    display: flex;
    align-items: center;

    & > :not(span) {
      width: 48%;
    }

    & > span {
      @include span-mobile;
      margin: 0 1%;
      color: $gray;
      letter-spacing: 0.05em;
    }
  }

  &__table {
    width: 100%;
    table-layout: fixed;
    text-align: left;
    padding: 10px 2.67% 0 2.67%;
  }

  &_emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #BCBDBF;
    background-color: $mainBackground;

    &_icon {
      margin-bottom: 15px;
      width: 50px !important;
      height: 50px !important;
    }
  }

  &__title {
    @extend %activity-table-title-mobile;
    position: relative;
    padding-bottom: 2.5%;
    cursor: pointer;

    &:first-child {
      padding-left: 11%;
      width: 17.6%;
    }

    &:nth-child(2) {
      width: 20%;
    }

    &:last-child {
      width: 10%;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 1.8vh;
      height: 1.8vh;
      margin-left: 0.53vw;
      margin-bottom: -0.88%;
      background: center / cover no-repeat;
    }

    &--asc {
      &::after {
        transform: rotate(270deg);
        background-image: url(../../../assets/images/backspace-arrow-16px.svg);
      }
    }

    &--desc {
      &::after {
        transform: rotate(90deg);
        background-image: url(../../../assets/images/backspace-arrow-16px.svg);
      }
    }

    &--inactive {
      &::after {
        transform: rotate(90deg);
        background-image: url(../../../assets/images/backspace-arrow-disabled.svg);
      }
    }
  }
}

.tableRow {
  &:nth-child(odd) {
    background-color: rgba($gray, 0.05);
    width: 100%;
  }

  & > * {
    @include main-tex-h5-mobile;
  }

  td {
    height: 7.5vh;

    &:first-child {
      display: flex;
      align-items: center;
    }

    padding: 4.6% 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:nth-child(2) {
      padding-right: 2%;
    }
  }

  &__value {
    color: rgba($mainBlack, 0.6);

    &:first-child {
      .tableRow__valueInner {
        align-items: center;

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 2%;
        }
      }
    }

    &:not(:first-child) {
      width: 22%;
    }
  }

  &__valueInner {
    display: flex;
  }

  &__icon {
    margin-right: 2.42%;
    margin-left: 4.83%;
    width: 6.4vw;
    height: 6.4vw;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__stepBtn {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: transparent;

    @include span-mobile;
    text-transform: uppercase;

    border: none;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      color: $gray_4;
    }

    & img {
      width: 4.3vw;
      height: 4.3vw;
      margin-left: 6%;

      &:first-child {
        margin-left: 0;
        margin-right: 6%;
        transform: rotate(180deg);
      }
    }
  }

  &__pages {
    display: flex;
    margin: 0 9.8%;
  }

  &__numBtm {
    display: flex;
    width: 8vw;
    height: 8vw;
    justify-content: center;
    align-items: center;

    @include span-mobile(bold);
    color: $white;
    letter-spacing: 0.05em;
    background: $lightDark;

    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1.3vw;
    }

    &--active {
      color: $mainBlack;
      background: $mainYellow;
      pointer-events: none;
    }

    &--split {
      pointer-events: none;
    }
  }
}
