@import '../../../assets/index.scss';

.keyManagement {
  height: 100%;

  &__main {
    position: relative;
    padding: 0 3.2%;
    height: 65%;
    overflow-y: scroll;
  }

  &__footer {
    height: 17%;
    padding: 0 3.2%;
    padding-top: 6.4%;

    button {
      width: 89vw !important;
      margin: 0 auto;
    }
  }

  &__clipboardMessage {
    @include span-gray-mobile();
    font-style: italic;
    margin-top: 3.4%;
    padding: 2.3%;
    text-align: center;
  }

  &__footerButton {
    height: 8.4vh !important;
  }
}

.tWrapper {
  padding: 6.8% 4%;
  max-height: 90%;

  &__table {
    width: 100%;
    table-layout: fixed;
    text-align: left;
  }

  &__title {
    @include span-mobile(bold);
    padding-bottom: 6.8%;

    //name
    &:nth-child(1) {
      width: 31.8%;
    }

    //keys
    &:nth-child(2) {
      width: 30%;
    }

    //issued
    &:nth-child(3) {
      width: 31.8%;
    }

    //button
    &:nth-child(4) {
      width: 6.4%;
    }
  }

  &__copy-icon {
    cursor: pointer;
    width: 4.3vw;
    height: 4.3vw;
  }

  &__button--close {
    position: relative;
    width: 5.34vw !important;
    height: 5.34vw !important;
    padding: 0 !important;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.6vw !important;
      height: 1.6vw !important;
    }
  }

  &__keysAmount {
    @extend %table-gray-text-mobile;
    font-style: italic;
    font-size: 1.8vh !important;
    line-height: 125% !important;
    margin-top: 8.3%;
  }
}

.tableRow {
  &:first-child {
    .tableRow__value {
      padding-top: 0;
    }
  }

  &:last-child {
    .tableRow__value {
      padding-bottom: 0;
    }
  }

  &__value {
    @extend %table-gray-text-mobile;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4.3vw 0;

    &:first-child {
      @extend %table-text-mobile;
    }

    &:not(:last-child) {
      padding-right: 4vw;
    }

    //keys
    &:nth-child(2) {
      button {
        margin-left: 5%;
      }
    }

    //button
    &:nth-child(4) {
      button:first-child {
        margin-right: 16%;
      }
    }
  }

  &__value-wrap {
    display: flex;
    align-items: center;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.apiKeyFormContainer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
  background-color: $mainBackground;
  overflow-y: auto;


  &__top {
    position: relative;
    height: 16.2vh;
    border-radius: 10px 10px 0 0;
    background-color: rgba($gray_9, 0.94);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
  }

  &__main {
    height: 83.8vh;
    padding: 2vh 5.3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__back {
    position: absolute;
    top: 17.9%;
    left: 5.5%;
    display: flex;
    align-items: center;
  }

  &__backIcon {
    margin-right: 1.9vw;
  }

  &__backText {
    @extend %body-sf;
  }

  &__plusIcon {
    position: absolute;
    top: 8.3%;
    right: 2.6%;
  }

  &__title {
    @extend %title-h2-mobile;
    position: absolute;
    bottom: 10%;
    left: 4.3%;
  }
}
