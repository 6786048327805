@import '../../../assets/index.scss';

.bankAccount {
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  position: relative;

  &__title {
    @include h4;
    font-size: 20px;
  }

  &__body_wrapper {
    max-width: 853px;
  }

  &__body {
    display: flex;
  }

  &__image {
    align-self: center;
    padding: 20px;

    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 1px solid #BCBDBF;
    }
  }

  &__details {
    flex: 1;
    padding: 20px 0;

    & > * {
      max-width: 368px;
    }
  }

  &__footer {
    align-self: flex-end;
    & > button {
      max-width: 204px;
    }
  }
}

.rowWithBorder {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 15px 0 14px;

  &:first-of-type::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;

    background: $mainBlack;
    opacity: 0.1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;

    background: $mainBlack;
    opacity: 0.1;
  }

  &__name {
    width: 30%;
    min-width: 124px;
    @include span(bold);
    letter-spacing: 0.05em;
    opacity: 0.6;
  }

  &__value {
    @include body;
  }
}