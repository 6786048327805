@import '../../../assets';

.header {
  width: 100%;

  &__inner {
    margin: 0 5.3vw;
  }

  &__top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($gray, 0.15);
  }

  &__bottom {
    margin-bottom: 3.75vh;
    padding-bottom: 3vh;
    border-bottom: 1px solid rgba($gray, 0.15);
  }

  &__left_side {
    display: flex;
    align-items: center;
  }

  &__back_button_splitter {
    display: none;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: $mainBlack;
    white-space: nowrap;
  }

  &__CTA_button {
    @extend %main-text-h4-mobile;
    text-transform: none !important;
    width: 89.3vw;
    height: 8.4vh !important;
    z-index: 5;
  }

  &__button {
    &--right {
      margin-left: auto;
    }
  }
}


.mobile_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 18%;

  &--with_default_padding {
    padding: 9.9% 8%;
  }

  &--with_small_padding {
    padding: 5.1% 4.8% 11.2%;
  }

  &--yellow.mobile_header--with_top_left_gradient {
    @include gradientBackground(-192deg, $mainYellow);
  }

  &--yellow.mobile_header--with_top_right_gradient {
    @include gradientBackground(192deg, $mainYellow);
  }

  &--green.mobile_header--with_top_left_gradient {
    @include gradientBackground(-192deg, $green_2);
  }

  &--green.mobile_header--with_top_right_gradient {
    @include gradientBackground(192deg, $green_2);
  }

  &--red.mobile_header--with_top_left_gradient {
    @include gradientBackground(-192deg, $red_2);
  }

  &--red.mobile_header--with_top_right_gradient {
    @include gradientBackground(192deg, $red_2);
  }

  &--with_menu {
    align-items: center;

    .mobile_header__right_icon_container {
      padding: 0;
    }
  }

  &__left_icon_container,
  &__right_icon_container {
    width: 8.53vw;
    height: 8.53vw;
    padding: 1.18%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__left_icon_container--for-menu {
    padding: 0;
  }

  &__title_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @extend %title-h2-mobile;
    text-align: center;
    padding: 0 8px;
  }

  &__logo {
    width: 23.7vw;
    height: 12.1vw;
    margin: 0 auto;
  }

  &__menu_container_bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100%;
    background-color: rgba($color: $mainBlack, $alpha: 0.85);
  }

  &__menu_container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 78vw;
    animation: menu_container_animation 0.8s;
    overflow: hidden;
  }

  &__colorful_ellipse {
    position: absolute;
    bottom: 0;
    right: 0;

    &--yellow {
      @include ellipseShadow($mainYellow);
    }

    &--red {
      @include ellipseShadow($ellipseRed);
    }

    &--green {
      @include ellipseShadow($ellipseGreen);
    }
  }
}

@keyframes menu_container_animation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
