@import '../../assets/index.scss';

%default-styles {
  @include span-mobile;
  padding: 12.3% 8.8% 7.9%;
  letter-spacing: 0.05em;
}

%date-styles {
  @include span-mobile;
  padding-left: 3.1%;
  padding-right: 10.8%;
  letter-spacing: 0.05em;

  & + .inputField__icon {
    top: 50%;
    right: 2.8%;
    transform: translateY(-50%);
    width: 2.2vh;
    height: 2.2vh;
  }
}

.inputField {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;

  &--solid {
    input {
      background: $white;
      border-bottom: 1px solid rgba(151,164,186,0.2);
    }

    &.date,
    &.date--active {
      border-bottom: none;
    }
  }

  &--transparent {
    input {
      background: $background;
      border-bottom: 1px solid rgba(151,164,186,0.2);
    }
  }

  &.currency::before {
    @include span-mobile;
    color: $gray;
    content: '£';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    padding: 12.3% 8.8% 7.9%;
  }

  &.date,
  &.date--active {
    height: 6vh;
    border-bottom: 1px solid rgba($gray, 0.6);

    .inputField__icon {
      top: 50%;
      right: 3%;
      transform: translateY(-50%);
      width: 3vh;
      height: 3vh;
    }
  }

  &__label {
    @include span-mobile;
    position: absolute;
    top: -10%;
    transform: translateY(-100%);
    color: $gray;
  }

  &__icon {
    position: absolute;
  }

  &__input {
    height: 6vh;
    border: none;

    &:focus {
      outline: none;
      border-color: $mainYellow;
    }

    &::placeholder {
      color: $black1;
      opacity: 1;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
    &[type="number"] { -moz-appearance: textfield; }

    &.default {
      @extend %default-styles;
    }

    &.search {
      @include body-mobile;
      padding-left: 16%;
      padding-right: 4.5%;

      &::placeholder {
        opacity: 0.3;
      }

      & + .inputField__icon {
        top: 50%;
        left: 3.7%;
        transform: translateY(-50%);
        width: 3vh;
        height: 3vh;
      }
    }

    &.date,
    &.date--active {
      background-color: $white;
      padding-left: 3%;

      &::placeholder {
        @include span-mobile(400);
        opacity: 1;
      }
    }

    &.currency {
      @extend %default-styles;
      padding-left: 20.2%;
    }
  }

  &__input--with-clear-button {
    padding-right: 5vh !important;
  }

  &__clearButton {
    position: absolute;
    top: 50%;
    right: 1.2vh;
    transform: translateY(-50%);
    width: 3vh;
    height: 3vh;
    cursor: pointer;
  }
}
