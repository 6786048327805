@import '../../assets/index.scss';

%default-styles {
  padding: 14px 10px 9px;
  @include span;
  letter-spacing: 0.05em;
}


.inputField {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;

  &:focus-within {
    outline: 1px solid $mainYellow;
  }

  &.date,
  &.date--active {
    .inputField__icon {
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
    }
  }

  &.date {
    border-bottom: 1px solid rgba($gray, 0.6);
  }

  &.date--active {
    border-bottom: 1px solid $mainBlack;
  }

  &--solid {
    input {
      background-color: $white;
      border-bottom: none;
    }
  }

  &--transparent {
    input {
      background: $background;
      border-bottom: 1px solid rgba(151,164,186,0.2);
    }
  }

  &.currency::before {
    content: '£';
    position: absolute;
    bottom: 10px;
    left: 10px;

    @include span;
    color: $gray;
  }

  &__label {
    margin-bottom: 4px;
    @include span(bold);
    color: $gray;
  }

  &__icon {
    position: absolute;
  }

  &__input {
    height: 40px;
    padding: 0 10px;
    background: $background;
    border: none;
    color: $black1;
    border-bottom: 1px solid rgba(151, 164, 186, 0.2);

    &:focus {
      outline: 1px solid $mainYellow;
    }

    &::placeholder {
      color: $black1;
      opacity: 1;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &.default {
      @extend %default-styles;
    }

    &.search {
      @include body;
      padding-left: 43px;
      padding-right: 12px;
      background: $white;

      &::placeholder {
        opacity: 0.3;
      }

      & + .inputField__icon {
        left: 10px;
        bottom: 10px;
        @include size(20px);
      }
    }

    &.date,
    &.date--active {
      background-color: $white;
      padding: 0 30px 0 10px;
      &::placeholder {
        @include span-black1();
        opacity: 1;
      }
    }

    &.currency {
      @extend %default-styles;
      padding-left: 23px;
    }
  }

  &__input--with-clear-button {
    padding-right: 35px !important;
  }

  &__clearButton {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
