@import '../../assets/index.scss';

.pin_input_form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__top {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 37.34% 0 11.84%;
  }

  &__bottom {
    width: 100%;
    padding: 0 5.3%;
    text-align: center;

    button {
      height: 7.5vh !important;
      margin-bottom: 4%;

      &:disabled {
        opacity: 0.8;
        background-color: $mainBlack !important;
      }
    }
  }

  &__title {
    @include body-mobile(700);
  }

  &__error {
    @extend %span-error-mobile;
    margin-top: 1.3%;
  }

  &__pin_input {
    margin: 2.4% 0 !important;

    &--error {
      input {
        color: $lightRed;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25.6vh;
    height: 31.2vh;
  }

  &__buttons_row {
    display: flex;
    justify-content: space-between;

    span {
      @extend %keyboard-button-mobile;
    }

    &:last-child {
      position: relative;
      justify-content: center;

      img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 2.1vh;
        width: 2.4vh;
      }
    }
  }
}
