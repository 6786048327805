@import "../../assets/index.scss";

.modalWrapper {
  width: 90%;
  height: 127px;
  position: absolute;
  background-color: #4D5055;
  color: white;
  padding: 15px 20px;
  border-radius: 12px;
  bottom: 2%;
  left: 5%;
}

.titleWrapper {
  @extend %fee-info-modal-title;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.textWrapper {
  @extend %fee-info-modal-text;
}
