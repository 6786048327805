@import '../../../assets';

.link_account {
  position: absolute;
  top: 107px;
  left: 50%;
  transform: translateX(-50%);
  height: fit-content;
  width: 51.228vh;

  &__inner {
    display: flex;
    flex-direction: column;
    align-content: start;
    padding: 4.0555vh 7.6841vh 3.2017vh;
    text-align: center;
  }

  &__title {
    @extend %main-text-h4;
    font-size: 1.93vh !important;
    line-height: 128%;
  }

  &__info_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.1345vh 0;
    padding: 1.0672vh 1.0672vh 1.8143vh;
    border: 1px solid $gray_11;

    &_icon {
      width: 4.06vh;
      height: 4.06vh;
    }

    &_text {
      @extend %main-text-small;
      font-size: 1.75vh !important;
      line-height: 125%;
      color: $lightDark;
      margin: 1.0672vh 0;
    }

    &_link {
      @extend %light-purple-link;
      font-size: 1.93vh !important;
    }
  }

  &__inputs {
    width: 100%;

    & > *:last-child {
      margin-top: 3.2017vh;
    }

    label {
      font-size: 1.7vh !important;
    }

    input {
      font-size: 1.93vh !important;
      line-height: 128%;
    }
  }

  &__support_team_text {
    @extend %light-gray-text;
    font-size: 1.7vh !important;
    margin-top: 0.8538vh;
    text-align: left;
  }

  &__support_team_link {
    @extend %light-purple-link;
    font-size: 1.7vh !important;
  }

  &__privacy_policy_text {
    @extend %light-dark-text;
    font-size: 1.7vh !important;
    margin-top: 1.8143vh;
  }

  &__privacy_policy_link {
    @extend %light-purple-link;
    font-size: 1.7vh !important;
  }

  &__button {
    height: 5.337vh !important;
    margin-top: 2.1345vh;
  }
}
