@import '../../../../../assets/';

.radio_button {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__input {
    display: none;

    & + label:before {
      content: url(../../../../../assets/images/radio-btn-unactive.svg);
      vertical-align: middle;
      margin-right: 5px;
      cursor: pointer;
    }

    &:checked + label:before {
      content: url(../../../../../assets/images/radio-btn-active.svg);
      cursor: pointer;
    }
  }

  &__label {
    @include main-tex-h5;
    margin-bottom: 10px;
    margin-top: 5px;
    width: fit-content;
  }

  &__subtitle {
    @extend %subscription-card-text;
    color: $gray_3;
    font-weight: lighter;
    padding: 0 23px;
  }
}
