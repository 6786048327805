@import '../../../../assets/index.scss';

.create_pin {
  height: 100%;

  &__back_icon {
    position: absolute;
    top: 14.2%;
    left: 4.8%;
    height: 6.4vw !important;
    width: 6.4vw !important;
  }

  &__top {
    position: relative;
    height: 8.8%;
    margin-top: -18.7%;
    text-align: center;
  }

  &__title {
    @extend %h2-mobile;
    text-transform: uppercase;
    margin-bottom: 1.87%;
  }

  &__subtitle {
    @include body-mobile();
    opacity: 0.5;
  }

  &__center {
    height: 83.7%;
    padding-top: 9.07%;
  }

  &:not(&--confirm) {
    .create_pin__back_icon {
      display: none;
    }
  }
}
