@import '../../../../assets';

.settings {
  display: flex;
  flex-direction: column;
  width: 29.831%;
  height: 714px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &_review {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 632px;
    padding: 23px 0 0;
    box-shadow: inset 0px 4px 40px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #EBEBEB;

    &_closed {
      height: 714px !important;
    }

    &_statusClosed {
      padding: 8px 25px;
      background: $gray_5 ;
      font-weight: bold;
    }
    &_statusOpen {
      padding: 8px 25px;
      background: $mainYellow;
      font-weight: bold;
    }

    &_header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 41px;

      &_title {
        @include body(700);
        margin-bottom: 24px;
      }

      & span {
        margin-bottom: 6px;
      }

      & a {
        background: $mainBackground;
        border-radius: 20px;
        padding: 4px 10px 4px 10px;
      }
      & img {
        height: 18px;
        width: 20px;
        margin-left: 5px;
        vertical-align: bottom;
      }
      &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin-bottom: 17px;
        border-radius: 5px;
        background-color: rgba($gray_15, 0.1);

        img {
          width: 25px;
          height: 25px;
        }
      }

      &_amount {
        @extend %payment-request-review-title;
        margin-top: 25px;
        margin-bottom: 10px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }

    &_scrollbar {
      @include customScrrollbar();
      flex: 1;
      overflow-y: scroll;
      padding: 0 20px;

      &:last-child {
        padding-bottom: 13px;
      }
    }

    &_info {
      @include span();
      line-height: 140%;
      display: flex;
      text-align: center;

      &_icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        margin-top: 2px;
      }

      &_link {
        @include span();
        line-height: 140%;
        text-decoration: underline;
      }
    }

    @media (max-width: 1395px) {
      height: 615px;
      padding: 23px 0 0;
    }
  }

  &_buttons {
    position: relative;
    padding: 0 20px;
    padding-top: 20px;
    background-color: $white;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;

    &_button {
      @include span(400);
      min-width: 150px;
      width: 100%;
      margin-right: 12px;
      padding: 8px 22px 8px 10px;

      @media (max-width: 1395px) {
        max-width: 70%;
        flex-direction: column;
        margin-bottom: 5px;
        align-items: center;
        align-content: center;
        padding-top: 12px;
      }
    }

    @media (max-width: 1395px) {
      flex-direction: column;
    }
  }
}

.settings_review_item {
  height: auto;
  padding: 17px 0;
  align-items: start;
  
  span:last-child {
    max-width: 190px;
  }
}

.settings_review_boolean_item {
  height: auto;
  padding: 17px 0;
}

.with-top-shadow {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    height: 16px;
    width: 100%;
    background: linear-gradient(180deg, rgba(33, 36, 43, 0) 0%, rgba(33, 36, 43, 0.1) 100%, rgba(33, 36, 43, 0.15) 100%);
  }
}

.with-bottom-shadow {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 16px;
    width: 100%;
    background: linear-gradient(0deg, rgba(33, 36, 43, 0) 0%, rgba(33, 36, 43, 0.1) 100%, rgba(33, 36, 43, 0.15) 100%);
  }
}

.bottom_line {
  position: relative;
  left: 0;
  bottom: 0;
  height: 16px;
  width: 100%;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 4px 4px;
}