@import '../../../assets/index.scss';

.container {
  height: 100%;

  &__center {
    position: relative;
    height: 60%;
    text-align: center;
  }

  &__title_container {
    padding: 0 17.3%;
  }

  &__title {
    @extend %title-h2-mobile;
  }

  &__description {
    @extend %grey-text-mobile;
    height: 36%;
    padding-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__description__ol {
    padding: 0;
  }

  &__description__li {
    text-align: center;
    list-style-position: inside;
  }

  &__footer {
    height: 22%;
    padding: 0 5.3%;
    text-align: center;
    display: flex;
    align-items: center;

    button {
      margin-bottom: 4%;
      height: 7.5vh !important;
    }
  }
}
