@import './typography.scss';

@mixin span-mobile($_weight: normal) {
  @include span($_weight);
  font-size: 1.8vh !important;
  line-height: 125% !important;
  color: $mainBlack;
}

@mixin span-gray-mobile($_weight: normal) {
  @include span-mobile($_weight);
  color: $gray;
}

%black-text-mobile {
  @extend %black-text;
  font-size: 2.1vh !important;
}

%small-text-mobile {
  @extend %small-text;
  font-size: 1.5vh !important;
}

%main-text-h4-mobile {
  @extend %main-text-h4;
  font-size: 2.7vh !important;
  line-height: 128% !important;
}

%grey-text-mobile {
  @extend %grey-text;
  font-size: 2.1vh !important;
}

%red-text-mobile {
  @extend %red-text;
  font-size: 2.1vh !important;
}

%title-h2-mobile {
  @extend %title-h2;
  font-size: 3.3vh !important;
  font-weight: 600 !important;
  line-height: 128% !important;
}

%title-h1-mobile {
  @extend %title-h1;
  font-size: 5.4vh !important;
}

@mixin h4-mobile($_weight: normal) {
  @include h4($_weight);
  font-size: 2.7vh !important;
  line-height: 128%;
}

@mixin h4-light-dark-mobile($_weight: normal) {
  @include h4-light-dark($_weight);
  font-size: 2.7vh !important;
  line-height: 128% !important;
}

%link-mobile {
  @extend %link;
  font-size: 2.7vh !important;
  line-height: 128% !important;
}

%link-text-mobile {
  @extend %link-text;
  font-size: 2.4vh !important;
  line-height: 125% !important;
}

%light-purple-link-mobile {
  @extend %light-purple-link;
  font-size: 2.7vh !important;
}

%table-text-mobile {
  @include family();
  font-size: 2vh !important;
  line-height: 123% !important;
  color: $mainBlack;
}

%table-gray-text-mobile {
  @extend %table-text-mobile;
  color: $gray;
}

%body-sf {
  @include family(
    $_weight: normal,
    $_family: (
      -apple-system,
      BlinkMacSystemFont,
      Mulish,
    )
  );
  font-size: 2.55vh;
  line-height: 129%;
  letter-spacing: -0.408px;
  color: $mainBlack;
}

@mixin body-mobile($_weight: normal) {
  @include family($_weight);
  font-size: 2.25vh;
  line-height: 140%;
  color: $mainBlack;
}

%title-h3-mobile {
  @extend %title-h3;
  font-size: 3vh !important;
}

%grey-text-mobile {
  @extend %grey-text;
  font-size: 2.1vh !important;
}

%light-dark-text-mobile {
  @extend %light-dark-text;
  font-size: 2.1vh !important;
}

%light-gray-text-mobile {
  @extend %light-gray-text;
}

%h2-mobile {
  @extend %h2;
  font-size: 3.6vh !important;
}

%span-link-mobile {
  @include family();
  font-size: 1.8vh;
  line-height: 140%;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: $textFieldBase;
}

%keyboard-button-mobile {
  @include family(700);
  font-size: 2.8vh;
  line-height: 116%;
  text-transform: uppercase;
  color: $mainBlack;
}

%span-error-mobile {
  @include family();
  font-size: 1.8vh;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: $lightRed;
}

%screen-small-title-mobile {
  @include family(700);
  font-size: 3.3vh;
  line-height: 115.5%;
  letter-spacing: 0.05em;
  color: $mainBlack;
}

%menu-item-text {
  @include family();
  font-size: 1.95vh !important;
  font-weight: 600 !important;
  line-height: 185% !important;
  color: $mainBlack;
}

%total-amount-mobile {
  @extend %total-amount;
  font-size: 3.9vh !important;
}

@mixin main-tex-h5-mobile($_weight: normal) {
  @include main-tex-h5($_weight);
  font-size: 1.9vh !important;
}

%title-h2-mobile-gray {
  @extend %title-h2-mobile;
  color: $gray_10 !important;
}

%main-text-small-mobile {
  @extend %main-text-small;
  font-size: 2.4vh !important;
  line-height: 125% !important;
}

%subscription-card-text-mobile {
  @extend %subscription-card-text;
  font-size: 1.8vh !important;
}

%subscription-card-title-mobile {
  @extend %subscription-card-title;
  font-size: 3.15vh !important;
}

%gift-aid-amount-card-value-mobile {
  @include family();
  font-size: 2.25vh;
  line-height: 110%;
  color: $black1;
}

%gift-aid-amount-card-description-mobile {
  @include family();
  font-size: 1.8vh;
  line-height: 140%;
  color: $mainBlack;
}

%gift-aid-table-title-mobile {
  @include family(700);
  font-size: 1.65vh;
  line-height: 127%;
  letter-spacing: 0.05em;
  color: $mainBlack;
}

%payment-details-details-amount-mobile {
  @extend %payment-details-details-amount;
  font-size: 5.4vh !important;
}

%gift-aid-details-amount {
  @include family(700);
  font-size: 2.25vh;
  line-height: 110%;
  color: $black1;
}

%gift-aid-details-donor-name {
  @include family();
  font-size: 2.25vh;
  line-height: 110%;
  color: $black1;
}

%request-table-title-mobile {
  @include family(700);
  font-size: 1.65vh;
  line-height: 127%;
  color: $mainBlack;
}

%requests-table-text-mobile {
  @extend %requests-table-text;
  font-size: 1.8vh !important;
}

%button-10px-mobile {
  @include family(700);
  font-size: 1.5vh !important;
  line-height: 140% !important;
}

%request-settings-input-requirement-mobile {
  @include family();
  font-size: 1.8vh;
  line-height: 140% !important;
  color: #909195;
}

%activity-table-title-mobile {
  @include family(700);
  font-size: 1.6vh;
  color: $mainBlack;
}

%toast-text-mobile {
  @include family();
  font-size: 2.4vh;
  line-height: 137%;
  color: $white;
}
