@import '../../../../../assets/';

.settings_review {
  width: 29.831%;
  padding: 23px 20px 13px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 41px;

    &_title {
      @include body(700);
      margin-bottom: 24px;
    }

    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      margin-bottom: 17px;
      border-radius: 5px;
      background-color: rgba($gray_15, 0.1);

      img {
        width: 25px;
        height: 25px;
      }
    }

    &_amount {
      @extend %payment-request-review-title;
      margin-bottom: 10px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;

      &--empty {
        color: $gray_17 !important;
      }

      &--set_by_payer {
        font-size: 22px;
      }
    }

    &_reference {
      @extend %requests-table-text;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid $gray_12;

    &:first-child {
      border-top: 1px solid $gray_12;
    }

    &_title {
      @extend %payment-request-review-item-title;
      margin-right: 5px;
    }

    &_value {
      @extend %requests-table-text;
      text-align: right;
    }

    &--boolean {
      .settings_review_item_value {
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-left: 5px;
        }
      }
    }
  }

  &_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    &_button {
      @include span(400);
      width: fit-content;
      min-width: 150px;
      margin-bottom: 20px;
    }
  }

  &_info {
    @include span();
    line-height: 140%;
    display: flex;

    &_icon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      margin-top: 2px;
    }

    &_link {
    @include span();
    line-height: 140%;
    text-decoration: underline;
    }
  }
}
