@import "../../../assets";

.gift_aid {
  height: 100vh;

  &__filters {
    padding: 0 3vh 3vh;
  }

  &__cards {
    height: 15.9vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3vh 3vh;

    .amount_card {
      flex: 1;
      height: 14vh;
    }

    .amount_card + .amount_card {
      margin-left: 1.8%;
    }
  }

  &__filters_top {
    display: flex;
    align-items: center;
  }

  &__filters_bottom {
    display: none;
    margin-top: 3vh;
  }

  &__filters--opened {
    .gift_aid__filters_bottom {
      display: block;
    }
  }

  &__title {
    @extend %title-h3-mobile;
    margin-top: 1.8%;
  }

  &__search_container {
    width: 87%;
  }

  &__date_container {
    padding-top: 2.99%;
    border-top: 1px solid rgba($gray, 0.15);
    margin-bottom: 5.98%;
  }

  &__reference_filter_container {
    padding-bottom: 3%;
    border-bottom: 1px solid rgba($gray, 0.15);
  }

  &__toggle_settings_icon {
    width: 4.8vh;
    height: 4.8vh;
    margin-left: 3.6%;
  }
}


.amount_card {
  display: flex;
  flex-direction: column;
  padding: 3% 3% 3.6%;
  border-radius: 12px;
  background: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &__icon {
    width: 3vh;
    height: 3vh;
    margin-bottom: 7.4%;
  }

  &__right_side {
    display: flex;
    flex-direction: column;
  }

  &__value {
    @extend %gift-aid-amount-card-value-mobile;
    margin-bottom: 4.94%;
  }

  &__description {
    @extend %gift-aid-amount-card-description-mobile;
    opacity: 0.6;
  }
}

.table_container {
  display: block;
  white-space: nowrap;
  width: 100%;

  &__table {
    padding: 8.27% 2.67% 2.67%;
    width: 100%;
    table-layout: fixed;
    text-align: left;
  }

  &__row {
    height: 7.5vh;
    &:nth-child(odd) {
      background: rgba($gray, 0.05);
    }
  }

  &__row_value {
    @include main-tex-h5-mobile();
    opacity: 0.6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-child {
      padding-left: 2.82%;
    }

    &:not(:last-child) {
      padding-right: 2.2%;
    }
  }


  &__title {
    @extend %gift-aid-table-title-mobile;
    padding-bottom: 2.82%;

    //date time
    &:first-child {
      width: 34.4%;
      padding-left: 2.82%;
    }

    //donor name
    &:nth-child(2) {
      width: 34%;
    }

    //gift aid
    &:nth-child(3) {
      width: 16.3%;
    }

    //amount
    &:last-child {
      width: 15.3%;
      padding-right: 2.54%;
    }
  }
}


.tWrapper {
  display: block;
  white-space: nowrap;

  &__title {
    @extend %activity-table-title-mobile;
    position: relative;
    padding-bottom: 2.5%;
    cursor: pointer;

    &:first-child {
      padding-left: 11%;
      width: 38%;
    }

    &:nth-child(2) {
      width: 27%;
    }

    &:last-child {
      width: 15%;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 1.8vh;
      height: 1.8vh;
      margin-left: 0.53vw;
      margin-bottom: -0.88%;
      background: center / cover no-repeat;
    }

    &--asc {
      &::after {
        transform: rotate(270deg);
        background-image: url(../../../assets/images/backspace-arrow-16px.svg);
      }
    }

    &--desc {
      &::after {
        transform: rotate(90deg);
        background-image: url(../../../assets/images/backspace-arrow-16px.svg);
      }
    }

    &--inactive {
      &::after {
        transform: rotate(90deg);
        background-image: url(../../../assets/images/backspace-arrow-disabled.svg);
      }
    }
  }
}
