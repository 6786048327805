@import "../../../assets";

.payment_request {
  background-color: $mainBackground;
  display: flex;
  flex-direction: column;

  &_upButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: $mainBlack;
    opacity: 0;
    min-height: 50px;
    width: 50px;

    &_visible {
      opacity: 80%;
    }

    &_icon {
      z-index: 10;
    }
  }

  &_csvItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 30px;
    padding: 20px 0;
    border-top: 1px solid $gray_12;

    &_subtitle {
      margin-bottom: 20px;
    }
  }

  &_table {
    border-radius: 4px;
  }

  img {
      width: 22px;
      height: 22px;
    }

  &__error {
    position: relative;

    &_border {
      position: absolute;
      left: 50%;
      top: -17.37%;
      transform: translate(-50%, 0);
      width: 89.3%;
      height: 1px;
      background-color: rgba($gray, 0.15);
    }
  }

  &__toast {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    width: 100%;
    margin-top: 1.8vh;
  }

  &_bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
    min-height: 80px;

    &_loadButton {
      margin: 20px 0;
      max-width: 120px;
    }
  }
}