@import "../../assets";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba($black1, 0.9);
  overflow-y: auto;

  & a {
    background: $mainBackground;
    border-radius: 20px;
    padding: 4px 10px 4px 10px;
    margin-bottom: 10px
  }

  &_info {
    @include span();
    line-height: 140%;
    display: flex;
    text-align: center;

    &_link {
      @include span();
      line-height: 140%;
      text-decoration: underline;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    min-width: 408px;
    margin-top: 166px;
    margin-bottom: 57px;
    padding: 23px 0 30px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  }

  &__top {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 20px;

    &:after {
      content: '';
      position: absolute;
      left: 0;      
      bottom: 0;
      width: 408px;
      height: 20px;
      background: linear-gradient(180deg, rgba(33, 36, 43, 0) 0%, rgba(33, 36, 43, 0.1) 100%, rgba(33, 36, 43, 0.15) 100%);
      transform: rotate(-180deg) translateY(-100%);
    }
  }

  &__title {
    @include body(700);
    margin-bottom: 26px;
  }

  &__transaction_icon {
    height: 50px;
    width: 50px;
    margin-bottom: 15px;
  }

  &__amount_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  &__amount {
    @extend %payment-details-details-amount;
    margin-bottom: 6px;
  }

  &__gift_aid_top_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -5px;
  }

  &__gift_aid_top_info_title {
    @include main-tex-h5(400);
    color: $gray_13;
  }

  &__gift_aid_top_info_line {
    width: 98px;
    height: 1px;
    margin: 10px 0;
    background-color: $gray_12;
  }

  &__gift_aid_top_info_amount {
    @include h4(400);
    color: $black1;
  }

  &__reference {
    @include main-tex-h5();
    opacity: 0.6;
    margin-bottom: 20px;

    &--gift-aid {
      margin-bottom: 11px;
    }
  }

  &__list {
    @include customScrrollbar();
    width: 100%;
    margin-bottom: 20px;
    max-height: 460px;
    overflow-y: auto;
  }

  &__list_boolean_value {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-left: 5px;
    }
  }

  &__gift_aid_item {
    align-items: flex-start !important;
    height: fit-content !important;
    padding: 17px 0 25px;

    & > *:last-child {
      flex: 1;
    }
  }

  &__list_address_value {
    line-height: 140%;
    white-space: break-spaces;
  }

  &__list_gift_aid_value {
    width: 58.236%;
    margin-left: auto;
    text-align: right;
    line-height: 140%;
  }

  &__close_button_container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      left: 0;      
      top: -20px;
      width: 408px;
      height: 20px;
      background: linear-gradient(180deg, rgba(33, 36, 43, 0) 0%, rgba(33, 36, 43, 0.1) 100%, rgba(33, 36, 43, 0.15) 100%);
      transform: translateY(-100%);
    }
  }

  &__close_button {
    height: 40px !important;
    padding: 0 50px;
    width: 140px;
  }
}

.list_item {
  display: flex;
  justify-content: space-between;
  padding-top: 17px;
  padding-bottom: 17px;
  margin: 0 20px;
  border-top: 1px solid $gray_12;

  &:first-child,
  &:last-child {
    position: relative;
    z-index: 1;
    margin: 0;
    background-color: $white;

    .list_item__name {
      margin-left: 20px;
    }

    .list_item__value {
      margin-right: 20px;
    }
  }

  &--with-element-value {
    align-items: center;
  }

  &:last-child {
    border-top: none;
    border-bottom: 1px solid $gray_12;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      height: 1px;
      width: 367px;
      background-color: $gray_12;
    }
  }

  &__name {
    @include span(700);
    line-height: 24px;
  }

  &__value {
    @include span();
    color: rgba($mainBlack, 0.6) !important;
    max-width: 190px;
    text-align: right;
    overflow-wrap: break-word;
    white-space: break-spaces;
  }
}
