@import '../../../../../assets/';

.radio_button {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__input {
    display: none;

    & + label:before {
      content: '';
      display: inline-block;
      background-image: url(../../../../../assets/images/radio-btn-unactive.svg);
      background-size: 2.7vh 2.7vh;
      vertical-align: middle;
      margin-right: 1.5%;
      cursor: pointer;
      width: 2.7vh;
      height: 2.7vh;
    }

    &:checked + label:before {
      content: '';
      display: inline-block;
      background-image: url(../../../../../assets/images/radio-btn-active.svg);
      background-size: 2.7vh 2.7vh;
      cursor: pointer;
      width: 2.7vh;
      height: 2.7vh;
    }
  }

  &__label {
    @include main-tex-h5-mobile();
    margin-bottom: 4.48%;
  }

  &__subtitle {
    @extend %requests-table-text-mobile;
    margin-top: 1.497%;
    margin-left: 6.87%;
  }
}
