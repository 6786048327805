@import '../../../assets/';

.date_range_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;

  &:last-child {
    padding-left: 15px;
  }

  & > p {
    @include span-black1(700);
    padding-right: 10px;
  }

  &__input {
    position: relative;
    width: 16vw;
    max-width: 224px;

    &_items {
      @include body(normal);
      position: absolute;
      list-style-type: none;
      top: 15.1%;
      right: 21px;
      width: 16vw;
      background: $white;
      padding: 5px 7px;
    }

    &:focus {
      outline: 1px solid $mainYellow;
    }
  }
}
