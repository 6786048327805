@import '../../../assets/index.scss';

.bankAccount {
  height: 100%;

  &__main {
    height: 65%;
    padding: 0 3.5% 5.3% 3.5%;
  }

  &__footer {
    height: 13%;
    padding: 3.5% 5.3%;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    padding: 6%;

    img {
      width: 32vw;
      height: 32vw;
      border-radius: 50%;
      border: 1px solid #BCBDBF;
    }
  }

  &__details {
    flex: 1;
    width: 100%;
    padding: 4.8%;
    padding-bottom: 6.8%;
  }

  &__changeButton {
    height: 8.4vh !important;
    font-weight: 700 !important;
  }
}

.rowWithBorder {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 5% 0;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;

    background: $mainBlack;
    opacity: 0.1;
  }

  &__name {
    width: 41%;
    @include span-mobile(bold);
    letter-spacing: 0.05em;
    opacity: 0.6;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__value {
    @include body-mobile;
    width: 59%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
