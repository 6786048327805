@import "../../../assets";

.payment_request {
  padding: 0 20px 23px 20px;
  width: 100%;
  overflow-y: auto;

  &_container {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: 788px;
  }

  &_error {
    margin-bottom: 40px;
  }

  &_content {
    display: flex;
    justify-content: space-between;
  }

  &_settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 68.5%;
    height: 100%;
    overflow-y: auto;

    &--expiry {
      overflow-y: visible;
    }

    &_requestTo {
      background-color: $white;
      border-radius: 4px;
      height: 120px;
      padding: 20px 0px 17px 30px;
      margin-bottom: 25px;

      & > h5 {
        @include main-tex-h5(700);
      }

      &_bankInfo {
        display: flex;
        margin-top: 15px;

        &_inner {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          height: 50px;
          width: 50px;
          border-radius: 100%;
          overflow: hidden;
          border: 0.5px solid #BCBDBF;

          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &_bankTitle {
        & > h3 {
          @extend %title-h3;
        }

        & > span {
         @include main-tex-h5;
          color: #7A7C80;
        }
      }
    }

    &_buttons {
      display: flex;
      margin-bottom: 12px;
      cursor: pointer;
      min-height: 53px;

      & > Button {
        margin-right: 10px;
      }

      & > Button:first-child {
        width: 12.5%;
      }

      & > Button:nth-child(2) {
        width: 19%;
      }

      & > Button:last-child {
        width: 18.8%;
      }
    }

    &_settingsFields {
      background-color: $white;
      position: relative;
      padding: 30px 30px 61px;
      flex: 1;
      border-radius: 4px;
    }
  }

  &_review {
    width: 29.8%;
  }

  &__toast {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    margin-top: -3px;
    margin-left: 23px;
  }
}

.amount_settings {
  &:nth-child(2) {
    margin-top: 30px;
  }

  &_header {
    @include main-tex-h5(bold);
    margin-bottom: 10px;
  }

  &_input {
    margin-left: 5px;
    border-color: transparent;
    width: 90%;
    outline: none;
    @extend %black-text;
  }

  &_date_input {
    width: 41.5%;
    height: 50px;
    margin-left: 23px;
    padding: 14px 10px;
    border-radius: 8px;
    border: 1px solid $gray_12;
    outline: none;
    margin-top: 10px;
    @extend %black-text;
    font-family: Mulish;

    &_error {
      width: 41.5%;
      height: 50px;
      margin-left: 23px;
      padding: 14px 10px;
      border-radius: 8px;
      border: 1px solid $red;
      outline: none;
      margin-top: 10px;
      @extend %black-text;
      font-family: Mulish;
    }
  }

  &_certain_date {
    border-top: 1px solid rgba(151, 164, 186, 0.15);
    padding-top: 15px;
    margin-top: 5px;
  }

  &_reference_input {
    width: 41.5%;
    height: 50px;
    padding: 14px 10px;
    border-radius: 8px;
    border: 1px solid $gray_12;
    outline: none;

    &_error {
      width: 41.5%;
      height: 50px;
      padding: 14px 10px;
      border-radius: 8px;
      border: 1px solid $red;
      outline: none;
    }

    &::placeholder {
      color: #D3D3D5;
    }

    &:focus {
      border-color: $mainYellow !important;
      outline: none;
    }
  }

  &_expire_input {
    width: 41.5%;
    height: 50px;
    padding: 14px 10px;
    margin-left: 23px;
    border-radius: 8px;
    border: 1px solid $gray_12;
    outline: none;

    &_error {
      width: 41.5%;
      height: 50px;
      padding: 14px 10px;
      margin-left: 23px;
      border-radius: 8px;
      border: 1px solid $red;
      outline: none;
    }

    &::placeholder {
      color: #D3D3D5;
    }

    &:focus {
      border-color: $mainYellow !important;
    }
  }
}
