@import '../../assets/index.scss';

.total {
  &__block {
    display: flex;
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 2.99% 0 3.59% 2.99%;
    background: $white;
    width: 44vw;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    &_images {
      display: flex;
      justify-content: space-between;
      margin-right: 10px;
    }

    &_feeInfo {
      width: 4.5vw !important;
      height: 2.3vh !important;
    }

    &_text_block {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    img {
      width: 3vh;
      height: 3vh;
      margin-bottom: 3.88%;
    }

    &_title {
      @include span-mobile();
      color: rgba($mainBlack, 0.6) !important;
      margin-top: 2.59%;
    }

    &_value {
      @include body-mobile();
      color: $black1 !important;
    }
  }
}