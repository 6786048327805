@import '../../../assets/index.scss';

.background {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(33, 36, 43, 0.7);
  height: 100vh;
  width: 100%;
}

.content {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  position: fixed;
  flex-direction: column;
  width: 315px;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  padding: 30px 16px 20px 19px;
  @include backgroundBlock($yellow, 8px);
  background: $white;

  &__title {
    @extend %main-text-h4;
    border-bottom: 1px dashed $mainBlack;
    padding: 0 0 14px;
  }

  &__text {
    @include h4();
    margin: 14px 0 29px;
  }

  &--mobile {
    width: 84%;
    max-width: initial;
    padding: 7.5% 3.8% 5.9% 4.6%;

    .content__title {
      @extend %main-text-h4-mobile;
      padding: 0 0 5%;
    }

    .content__text {
      @include h4-mobile();
      margin: 5% 0 13.8%;
    }

    button {
      height: 6.6vh !important;
    }
  }
}
