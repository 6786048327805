@import '../../../../../assets/';

.input {
  &--error {
    border-color: $red !important;
  }

  &__amount_settings_with_currency {
    display: flex;
    align-items: center;

    height: 7.5vh;
    padding: 0 2.989%;
    padding: 0 1.5vh;

    margin-bottom: 5.974%;
    border-radius: 8px;
    border: 1px solid $gray_14;

    & > span {
      @extend %black-text-mobile;
    }
  }

  &__amount_settings_input_requirement {
    @extend %request-settings-input-requirement-mobile;
    margin-bottom: 5.974%;
  }

  &__amount_settings_input_requirement_other_setting {
    @extend %request-settings-input-requirement-mobile;
    margin-bottom: 5.974%;
    margin-left: 6.87%;
  }

  &__expiry_date_input {
    position: relative;

    &_inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_value {
      @extend %black-text-mobile;
    }

    &_icon {
      width: 2.7vh;
      height: 2.7vh;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 82.7vw;
      max-width: 100%;
      opacity: 0;
  
      &::-webkit-datetime-edit-fields-wrapper,
      &::-webkit-date-and-time-value {
        display: none;
      }
  
      &::-webkit-calendar-picker-indicator {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
  
      &:focus {
        outline: none;
      }
    }
  }
}
