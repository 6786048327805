@import '../../../assets/index.scss';

.subscriptionManagement {
  height: 100%;

  &__main {
    height: 63.1%;
    padding: 0 4.3% 8% 6.4%;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 18.9%;

    button {
      min-width: 51%;
      width: fit-content;
      margin-bottom: 4.5%;
    }
  }

  &__new-plan-button {
    text-transform: uppercase !important;
  }

  &__subscription {
    position: relative;
    background: $white;
    padding: 6%;
    padding-right: 0;
    -webkit-box-shadow: -8px 8px 0px 0px $mainYellow;
    box-shadow: -8px 8px 0px 0px $mainYellow;

    button {
      position: absolute;
      top: 13%;
      right: 4.5%;
      transform: translateX(-50%);
      width: 4.3vw;
      height: 4.3vw;
    }
  }

  &__subscriptionName {
    @include h4-mobile;
    margin-bottom: 3.7%;
  }

  &__subscriptionTimeStamp {
    @extend %black-text-mobile;
    opacity: 0.6;
    margin-bottom: 1%;
    display: block;
  }

  &__subscriptionTimeStampDate {
    @extend %black-text-mobile;
    opacity: 0.6;
  }

  &__subscriptionCloseIcon {
    img {
      height: 4.3vw;
      width: 4.3vw;
    }
  }

  &__activateButton {
    height: 8.4vh !important;
    width: 51.5vw !important;
    background-color: $mainYellow !important;
  }

  &__closeAccountLink {
    @extend %link-text-mobile;
    font-weight: 700 !important;
    font-size: 2vh !important;
    text-decoration: underline;
  }

  &__billingDataLinkContainer {
    margin-top: 6.87%;
    text-align: center;
  }

  &__billingDataLink {
    @extend %link-text-mobile;
  }

  &__refreshButton{
    @extend %link-text-mobile;
    text-decoration: underline;
    cursor: pointer;
  }
}
