@import '../../../assets/';


.date_range_input {
  position: relative;

  & > p {
    @include span-mobile(700);
    margin-bottom: 1.5%;
  }
}
