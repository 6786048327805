@import "../../../../assets";

.error_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #FFFFFF;
  border-radius: 4px;
  margin-bottom: 20px;
}

.error_container {
  display: flex;
  justify-content: flex-start;
  padding: 20px 40px 20px 23px;
}

.error_container_icon {
  width: 26px;
  height: 23px;
  margin: 5px 13px 0 0;
}

.error_title {
  @extend %error-message-title;
  margin-bottom: 5px;
}

.error_description {
  @extend %error-message-text;
}

.error_description_link {
  text-decoration: underline;
}

.error_button {
  display: flex;
  align-items: center;
  padding: 14px 62px;
  border-radius: 0 0 4px 4px;
  background-color: $red_3;
}

.error_button_link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.error_button_icon {
  width: 18px;
  height: 14px;
  margin-left: 14px;
}

.error_button_text {
  @extend %error-message-button;
  text-decoration: underline;
}
