@import '../../../../../assets/';

.switch_button {
  display: flex;

  & > img {
    cursor: pointer;
  }

  &_text_container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  &_title {
    @include main-tex-h5(bold);
  }

  &_subtitle {
    @extend %subscription-card-text;
    color: $gray_3;
    font-weight: lighter;
  }

  &_is_active {
    padding-top: 15px;
    margin: 15px 0 30px 50px;
    border-top: 1px solid rgba(151, 164, 186, 0.15);
  }

  &_tyn {
      margin: 15px 0 30px 50px;
  }
}

.switch_button:not(:first-child) {
  border-top: 1px solid rgba(151, 164, 186, 0.15);
  padding-top: 16px;
  margin-top: 15px;
}
