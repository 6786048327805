@import '../../../assets/index.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__top {
    position: relative;
    padding: 2.7vh 5.3% 1.5vh;

    &--with_shadow {
      box-shadow: 0px 4px 10px rgba(60, 67, 77, 0.05), 0px 2px 30px rgba(60, 67, 77, 0.1);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3vh;
    padding-bottom: 2.7vh;
    border-bottom: 1px solid rgba($gray, 0.15);
  }

  &__title {
    @extend %title-h3-mobile;
  }

  &__close_icon {
    position: absolute;
    top: 2.1vh;
    right: 3vh;
    width: 4.8vh;
    height: 4.8vh;
  }

  &__search_wrapper {
    position: relative;

    &__search_request {
      height: 7.6vh;
      box-sizing: border-box;

      img {
        position: absolute;
        top: 25.65%;
        left: 2.99%;
        height: 3.6vh;
        width: 3.6vh;
      }

      input {
        @extend %black-text-mobile;
        outline: none;
        height: 100%;
        width: 100%;
        padding: 0 1.8% 0 13.5%;
        border: 1px solid $gray_14;
        border-radius: 8px;
        background: $white;

        &:focus {
          border-color: $mainYellow;
        }
      }
    }
  }

  &__bank_items {
    @include customScrrollbarMobile();
    height: 78.6%;
    padding: 1.5vh 5.3%;
    overflow-y: auto;

    & > *:not(:last-child) {
      margin-bottom: 1.2vh;
    }
  }
}
