@import '../../../assets/index.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  &__title {
    @extend %title-h2;
    width: 100%;
    text-transform: uppercase;
    margin: 32px auto 36px;
  }

  &__text {
    margin: 0 auto;
    @extend %main-text-h4;
    @include family(normal);
  }

  &__button {
    @extend %main-text-h4;
    @include family(600);
    cursor: pointer;
    margin-top: 250px;
    text-decoration: underline;
  }
}
