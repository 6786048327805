@import '../../../../../assets/';

.settings_checkbox {

  &__input {
    display: none;

    & + label:before {
      content: '';
      display: inline-block;
      background-image: url(../../../../../assets/images/checkbox-base.svg);
      background-size: 2.7vh 2.7vh;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 1.4%;
      width: 2.7vh;
      height: 2.7vh;
    }

    &:checked + label:before {
      content: '';
      display: inline-block;
      background-image: url(../../../../../assets/images/checkbox-active.svg);
      background-size: 2.7vh 2.7vh;
      cursor: pointer;
      margin-right: 1.4%;
      width: 2.7vh;
      height: 2.7vh;
    }
  }

  &__label {
    @include main-tex-h5-mobile;
  }

  &__subtitle {
    @extend %subscription-card-text;
    color: $gray_3;
    font-weight: lighter;
    padding-left: 23px;
  }

  &__subtitle_others {
    @include span-mobile();
    color: $gray_13 !important;
    padding-left: 8.075%;
    margin-bottom: 5.265%;
  }
}
