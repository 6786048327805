@import '../../../../assets/';

.amount_settings_expire_input {
  @extend %black-text-mobile;
  height: 7.5vh;
  width: 91.93%;
  padding: 4.913% 3.51%;
  border-radius: 8px;
  margin-left: 6.87%;
  margin-top: -1.756%;
  border: 1px solid $gray_14;
  outline: none;

  &::placeholder {
    color: #D3D3D5;
  }
}

.amount_settings_thank_you_note_input {
  width: 93%;
}

.amount_settings_reference_input {
  @extend %black-text-mobile;
  height: 7.5vh;
  padding: 4.78% 2.989%;
  border-radius: 8px;
  border: 1px solid $gray_14;
  outline: none;

  &::placeholder {
    color: #D3D3D5;
  }
}
