*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  overflow-y: hidden;
  height: 100%;
  margin: 0;
  font-family: Mulish, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

h1, h2, h3, h4, h5, h6, p,
table, tbody, thead, tr, th, td,
input, button, ul {
  margin: 0;
  padding: 0;
}

table {
  border-spacing: 0;
}

#root {
  height: 100%;

  background-color: #F5F7FA;
  overflow-x: auto;
  visibility: hidden;
}

.root--visible {
  visibility: visible !important;
}
