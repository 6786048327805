@import '../../../assets/index.scss';

.container {
  position: fixed;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  text-align: center;

  &__text {
    @extend %main-text-h4;
    margin: 38px 0 28px;

    &__error {
      color: $red;
      font-size: 20px;

      & span {
        text-decoration: underline;
      }
    }
  }

  &__button {
    text-decoration: underline;
    @extend %main-text-h4;
    @include family(normal);
    cursor: pointer;
  }
}
