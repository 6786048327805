@import './color.theme.scss';

@mixin size($_width, $_height: $_width) {
  width: $_width;
  height: $_height;
}

@mixin backgroundBlock($_color, $_bottom, $_left: $_bottom) {
  box-shadow: -$_left $_bottom 0px 0px $_color;
  -moz-box-shadow: -$_left $_bottom 0px 0px $_color;
  -webkit-box-shadow: -$_left $_bottom 0px 0px $_color;
}

@mixin ellipseShadow($_color) {
  -webkit-box-shadow: 0px 0px 180px 80px $_color;
  -moz-box-shadow: 0px 0px 180px 80px $_color;
  box-shadow: 0px 0px 180px 80px $_color;
}

@mixin gradientBackground($_deg: 192deg, $_color: $mainYellow) {
  background: linear-gradient($_deg, $_color -4.4%, rgba(254, 230, 0, 0) 60%);
}

@mixin customScrrollbar() {
  scrollbar-width: auto;
  scrollbar-color: $gray_17 #0000;
  overflow-y: overlay !important;

  &::-webkit-scrollbar {
    display: block;
    width: 18px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $gray_17;
    background-clip: padding-box;
    border: 6px solid #0000;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

@mixin customScrrollbarMobile() {
  scrollbar-width: auto;
  scrollbar-color: $gray_17 #0000;
  overflow-y: overlay !important;

  &::-webkit-scrollbar {
    display: block;
    width: 3.2vw;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 3.2vw;
    background: $gray_17;
    background-clip: padding-box;
    border: 0.8vw solid #0000;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}