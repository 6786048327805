@import '../../../assets/index.scss';

.subscriptionManagement {
  padding: 0 20px 113px 20px;

  &__buttons {
    display: flex;
    align-items: center;

    & > * {
      width: 171px;
    }

    button + button {
      margin-left: 8px;
    }
  }

  &__main {
    max-width: 398px;
    margin-top: 20px;
    text-align: center;
  }

  &__new-plan-button {
    text-transform: uppercase !important;
  }

  &__subscription {
    position: relative;
    background: $white;
    padding: 21px 25px;
    margin-bottom: 16px;
    text-align: left;

    button {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 16px;
    }
  }

  &__subscriptionName {
    @include h4;
    margin-bottom: 11px;
  }

  &__subscriptionTimeStamp {
    @extend %black-text;
    opacity: 0.6;
  }

  &__billingDataLink {
    @extend %link-text;
    margin-top: 16px;
  }

  &__refreshButton{
    @extend %link-text;
    margin: 0 auto;
    margin-top: 16px;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }

  &__close-account-button {
    @extend %link-text;
    text-decoration: underline;
  }
}
