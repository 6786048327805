@import '../../assets/index.scss';

.wrapper {
  background: $white;

  &.main {
    border-radius: 4px;
  }

  &.border {
    border: 1px solid $border;
    border-radius: 8px;
  }

  &.overflow_h {
    overflow: hidden;
  }

  &.bRadius12 {
    border-radius: 12px;
  }

  &.with-shadow {
    box-shadow: 0px 4px 10px rgba(33, 36, 43, 0.05), 0px 2px 30px rgba(237, 187, 31, 0.1);
  }
}
