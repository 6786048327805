@import '../../assets/index.scss';

.pin_input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__label {
    @include span(bold);
    letter-spacing: 0.05em;
  }

  input {
    height: 30px;
    width: 23px;
  }

  input + input {
    &:not(:first-child) {
      margin-left: 7px;
    }
  }

  img {
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translate(100%, -50%);
    width: 20px;
    height: 20px;
    margin-left:5px;
    cursor:pointer;
  }

  &--mobile {
    .pin_input__label {
      @include span-mobile(bold);
    }

    input {
      @include body-mobile();
      height: 4.5vh !important;
      width: 3.4vh !important;
    }

    input + input {
      &:not(:first-child) {
        margin-left: 1vh !important;
      }
    }

    img {
      width: 1.8vh;
      height: 1.8vh;
      min-width: 12px;
      min-height: 12px;
      right: -9.2%;
      margin-left: 0;
    }
  }
}
