@import '../../../assets/';

.too_many_attempts {
  height: 100%;

  &__center {
    height: 67.32%;
    padding: 0 5.334%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__bottom {
    height: 14.68%;
    padding: 0 5.334%;
    text-align: center;
  }

  &__countdown {
    @include h4-mobile(600);
    color: $red;
  }

  &__top_icon {
    width: 17.07vw;
    height: 17.07vw;
  }

  &__title {
    @extend %main-text-h4-mobile;
    text-transform: uppercase;
    margin: 7.165% 0 2.986%;
  }

  &__text {
    @extend %light-dark-text-mobile;
  }

  &__countdown {
    @include h4-mobile(600);
    color: $red;
  }
}
