@import '../../../../assets';

.settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &_review {
    width: 100%;
    padding: 23px 20px 13px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #EBEBEB;

    &_statusClosed {
      padding: 8px 25px;
      background: $gray_5 ;
      font-weight: bold;
    }
    &_statusOpen {
      padding: 8px 25px;
      background: $mainYellow;
      font-weight: bold;
    }

    &_header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 41px;

      &_popup {
        position: absolute;
        width: 172px;
        right: 0;
      }

      &_threeDots {
        position: absolute;
        top: 5px;
        right: 0;
        height: 38px;

        &_img {
          height: 100%;
          width: 100%;
        }
      }

      &_title {
        @include body(700);
        margin-bottom: 24px;
      }

      & span {
        margin-bottom: 6px;
      }

      & a {
        background: $mainBackground;
        border-radius: 20px;
        padding: 4px 10px 4px 10px;
      }
      & img {
        margin-left: 5px;
        vertical-align: bottom;
      }
      &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin-bottom: 17px;
        border-radius: 5px;
        background-color: rgba($gray_15, 0.1);

        img {
          width: 25px;
          height: 25px;
        }
      }

      &_amount {
        @extend %payment-request-review-title;
        margin-top: 25px;
        margin-bottom: 10px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }

    &_scrollbar {
      max-height: 80%;
      overflow-y: scroll;

      &_button {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 50px;
        color: $mainBlack;
        text-decoration: underline;
        font-weight: 700;
        font-size: 12px;
        border-bottom: 1px solid $gray_12;

        &_title {

        }

        &_img {
          height: 12.1px !important;
          width: 7.3px;

          &_rotate {
            transform: rotate(180deg);
          }
        }
      }
    }

    &_scrollbar_withButtons {
      max-height: 65%;
      @media (max-width: 1395px) {
        max-height: 65%;
      }
    }


    &_info {
      @include span();
      line-height: 140%;
      display: flex;
      text-align: center;

      &_icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        margin-top: 2px;
      }

      &_link {
        @include span();
        line-height: 140%;
        text-decoration: underline;
      }
    }

    @media (max-width: 1395px) {
      padding: 23px 20px 0;
    }
  }
}

.settings_review_item {
  height: auto;
  padding: 17px 0;
  align-items: start;
}

.settings_review_boolean_item {
  height: auto;
  padding: 17px 0;
}
