@import '../../assets/index.scss';

%small-border {
  box-shadow: 0px 0px 0px 1px rgba(147, 136, 231, 1);
  -moz-box-shadow: 0px 0px 0px 1px rgba(147, 136, 231, 1);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(147, 136, 231, 1);
}

.panel {
  display: flex;
  justify-content: center;
  width: 100%;

  &__image {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-right: 3.3%;
    width: 7.5vh;
    height: 7.5vh;
    background: #fff;
    border-radius: 50%;

    &_logo {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &_border {
      @extend %small-border;
    }
  }

  .spinner {
    @include size(40px);
  }

  &__name {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 59.4%;
    background: $white;
    @extend %small-border;
    border-radius: 25px;

    &_title {
      @extend %main-text-h4-mobile;
    }
  }
}
