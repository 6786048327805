@import '../../../../assets/index.scss';

.create_pin {
  padding-top: 5vh;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header_title {
    @extend %title-h1;
    text-transform: uppercase;
    margin-top: 35px;
    margin-bottom: 10px;
  }

  &__header_subtitle {
    @include body();
    text-align: center;
  }

  &__main {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
  }
}

.create_pin_form {
  padding: 30px 113px;
  background-color: $white;

  &__header {
    text-align: center;
  }

  &__title {
    @extend %h2;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  &__subtitle {
    @include body();
  }

  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & > button {
      @include body();
      margin-top: 3vh;
      max-width: 245px;
    }

    &--with-error {
      input {
        color: $lightRed;
        border-color: $lightRed !important;
      }
    }
  }

  &__input_wrap {
    & + & {
      margin-top: 24px;
    }
  }

  &__error_message {
    @include main-tex-h5();
    color: $lightRed;
    margin-top: 10px;
  }
}

.create_pin_form_wrapper {
  box-shadow: 0px 4px 10px rgba(33, 36, 43, 0.05),
    0px 2px 30px rgba(237, 187, 31, 0.1);
}
