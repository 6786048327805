@import '../../assets/index.scss';

%same-disabled-style {
  color: $white;
  background-color: $gray_2;

  img {
    filter: brightness(0) invert(1);
  }
}

%same-disabled-yellow-style {
  color: $mainYellow;
  background-color: rgba($yellow, .3);
}

.button {
  width: 100%;
  height: 40px;
  padding: 0 0px;
  text-align: center;
  white-space: nowrap;
  border: unset;
  @include family();

  &:disabled {
    touch-action: none;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.square {
    @include span;
    text-transform: uppercase;

    &:disabled { @extend %same-disabled-style; }
  }

  &.rounded {
    border-radius: 40px;
    text-transform: uppercase;
    &:disabled { @extend %same-disabled-style; }
  }

  &.littleRounded {
    border-radius: 10px;
    text-transform: uppercase;
    &:disabled { @extend %same-disabled-style; }
  }

  &.login {
    border-radius: 10px;

    &:disabled {
      opacity: 0.8;
    }
  }

  &.black {
    color: white;
    background: $mainBlack;
  }

  &.blackRounded {
    @include main-tex-h5(bold);
    background-color: $mainBlack;
    color: $white;
    text-transform: uppercase;
    border-radius: 6px;
    height: 53px !important;
  }

  &.yellow {
    background: $mainYellow;
    &:disabled {
      @extend %same-disabled-yellow-style;
    }
  }

  &.darkYellow {
    @include family(bold);
    background: $yellow;
  }

  &.modalYellow {
    @include family(bold);
    background: $mainYellow;
    color: $lightDark;
  }

  &.yellowInactive {
    @extend %same-disabled-yellow-style;
  }

  &.transparent {
    background: transparent;
  }

  &.transparentWithBorder {
    background: transparent;
    border: 1px solid $mainBlack;
  }

  &.white {
    font-size: 18px;
    background: transparent;
    border: 1px solid $gray_4;
    color: $gray_4;
    text-transform: lowercase;
  }

  &.whiteRounded {
    @include main-tex-h5(bold);
    background-color: $white;
    text-transform: uppercase;
    border-radius: 6px;
    height: 53px !important;
  }

  &.squareWithIco {
    display: flex;
    justify-content: center;
    align-items: center;

    @include span(600);
    letter-spacing: 0.05em;
    background: $mainYellow;

    &:disabled { @extend %same-disabled-style; }

    & > img {
      width: 14px;
      margin-left: 8px;
    }
  }

  &.roundedWithIco {
    display: flex;
    justify-content: center;
    align-items: center;

    @include h4(600);
    background: $mainYellow;

    border-radius: 40px;

    &:disabled { @extend %same-disabled-style; }

    & > img {
      width: 14px;
      margin-left: 8px;
    }
  }

  &__spinner {
    margin: 0 auto;
  }
}


