@import "../../../assets";

.container {
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 82%;
    padding: 5.87% 9.87% 9.87%;
    background: linear-gradient(6deg, #ff4d5f, 40%, rgba(254, 230, 0, 0) 85%);
    text-align: center;
  }

  &__content_top {
    height: 45.1%;
  }

  &__content_bottom {
    height: 54.9%;
    display: flex;
    align-items: flex-end;
  }

  &__icon {
    height: 9.596vh;
    width: 9.596vh;
  }

  &__title {
    @extend %title-h2-mobile;
    font-weight: 700 !important;
    text-transform: uppercase;
    width: 100%;
    margin: 7.97% auto 11.96%;
  }

  &__text {
    @include h4-mobile();
    margin: 0 auto;
  }

  &__link {
    @extend %link-mobile;
  }
}
