@import '../../../assets/index.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    @include size(100%, 100%);
}

.data_container {
    background-color: $white;
    border-radius: 12px;
    @include size(425px, 350px);

    &__top {
        display: flex;
        padding-left: 1%;
        padding-right: 1%;
        flex-direction: column;
        @include size(100%, 30%);
        justify-content: center;
        align-items: center;
    }

    &__center {
        display: flex;
        flex-direction: column;
        @include size(100%, 40%);
        justify-content: center;
        align-items: center;
    }

    &__bottom {
        display: flex;
        padding-left: 7%;
        padding-right: 7%;
        flex-direction: column;
        @include size(100%, 30%);
        justify-content: space-around;
        align-items: center;
    }

    &__title {
        @extend %title-h3;
        @include family(bold);
        font-size: 24px;
        white-space: nowrap;
        color: $mainBlack;
    }

    &__title_small {
        @extend %title-h3;
        @include family(bold);
        font-size: 20px;
        //white-space: nowrap;
        color: $mainBlack;
        text-align: center;
    }

    &__text {
        @include body;
        color: $gray_3;
        margin: 5px;
        text-align: center;
    }

    &__pin_title {
        font-size: 15px;
        @include family(bold);
        color: $mainBlack;
    }

    &__pin_forgot {
        @include span;
        color: $textFieldBase;
        cursor: pointer;
        text-decoration: underline;
        margin: 5px;
    }

    &__account_not_found_image {
        height: 128px;
        aspect-ratio: 1;
        background: top / cover no-repeat url(./../../../assets/images/login/create_subscription.png);
    }
}

.incorrect_pin__text {
    margin-top: 5px;
    color: $lightRed;
}
