@import '../../../../../assets/';

.switch_button {
  display: flex;
  align-items: start;

  & > img {
    cursor: pointer;
    width: 6vh;
    height: 3vh;
  }

  &_text_container {
    display: flex;
    flex-direction: column;
    margin-left: 2.989%;
  }

  &_title {
    @include main-tex-h5-mobile(bold);
  }

  &_subtitle {
    @include span-mobile();
    color: $gray_13;
    margin-top: 2.23%;
  }

  &_is_active {
    padding-top: 4.48%;
    margin: 4.48% 0 8.956% 14.93%;
    border-top: 1px solid rgba($gray, 0.15);
  }

  &_tyn {
      margin: 4.48% 0 8.956% 14.93%;

      & > span {
        margin-left: 0;
      }
  }
}

.switch_button:not(:first-child) {
  border-top: 1px solid rgba($gray, 0.15);
  padding-top: 16px;
  margin-top: 15px;
}
