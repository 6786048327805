@import '../../../assets/';

.request_details {
  height: 100%;

  &__header {
    padding: 9.334% 5.334% 0;
    margin-bottom: 4.8%;

    &_inner {
      display: flex;
      align-items: center;
      padding-bottom: 8.956%;
      border-bottom: 1px solid rgba($gray, 0.15);
    }

    &_back_icon {
      width: 4.8vh;
      height: 4.8vh;
      margin-right: 2.986%;
    }

    &_title {
      @extend %title-h3-mobile;
    }

    &_close_icon {
      width: 4.8vh;
      height: 4.8vh;
      margin-left: auto;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;

    &_title {
      line-height: 120%;
      letter-spacing: 0.03em;
      color: $mainBlack;
    }

    &_center {
      width: 100%;

      &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgba($gray_15, 0.15);
      }
    }

    &_amount {
      font-weight: bold;
      line-height: 120%;
      color: $mainBlack;
    }

    &_reference {
      @include main-tex-h5-mobile();
    }

    &_bottom {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &_hint {
      line-height: 125%;
      color: $gray_13;
      text-align: center;
    }

    &_copy_button {
      @include body-mobile(400);
      line-height: 146% !important;
      width: 100%;
    }

    &_save_QR_link {
      @include body-mobile();
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__popup {
    position: static;
    transform: none;
    width: 100%;
    margin-bottom: 10px;
    margin-top: -58px;
  }
}
