@import "../../../assets/";

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__stepBtn {
    display: flex;
    height: 100%;

    @include span;
    text-transform: uppercase;

    border: none;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      color: $gray_4;
    }

    & img {
      margin-left: 2px;

      &:first-child {
        margin-left: 0;
        margin-right: 2px;
        transform: rotate(180deg);
      }
    }
  }

  &__pages {
    display: flex;
    margin: 0 33px;
  }

  &__numBtm {
    display: flex;
    @include size(30px);
    justify-content: center;
    align-items: center;

    @include span(bold);
    color: $white;
    letter-spacing: 0.05em;
    background: $lightDark;

    cursor: pointer;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &--active {
      color: $mainBlack;
      background: $mainYellow;
      pointer-events: none;
    }

    &--split {
      pointer-events: none;
    }
  }
}
