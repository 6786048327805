@import '../../assets/index.scss';

.background {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;

    background: bottom / cover no-repeat url(./../../assets/images/backgrounds/single-bg-img.jpg);

    & > :not(span) {
        //z-index: 0;
    }

    &__colorfulEllipse {
        position: fixed;
        bottom: 348px;
        left: 277px;

        &--yellow {
            @include ellipseShadow($mainYellow);
        }

        &--red {
            @include ellipseShadow($ellipseRed);
        }

        &--green {
            @include ellipseShadow($ellipseGreen);
        }
    }

    & > :last-child {
        flex: 1;
        overflow: auto;
    }
}

.logo {
    position: absolute;
    top: 40px;
    left: 40px;
    @include size(111px, 56px);
}

.back {
    position: absolute;
    top: 48px;
    right: 30px;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        margin:0 15px 0 0;
    }
}
