@import '../../../assets';

.link_account {
  height: 100%;

  &__top {
    height: 9%;
    padding: 16px 17px 16px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    @extend %title-h2-mobile-gray;
  }

  &__close_icon {
    width: 3.6vh;
    height: 3.6vh;
  }

  &__content {
    height: 91%;
    padding: 0 5.334%;
  }

  &__info_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2.986%;
    padding-bottom: 5.075%;
    border: 1px solid $gray_11;
    background-color: $white;

    &_icon {
      width: 5.7vh;
      height: 5.7vh;
    }

    &_text {
      @extend %main-text-small-mobile;
      color: $lightDark;
      margin: 3.195% 0;
    }

    &_link {
      @extend %light-purple-link-mobile;
    }
  }

  &__inputs {
    padding: 5.673% 0 2.27%;

    & > *:last-child {
      margin-top: 5.673%;
    }
  }

  &__support_team_text {
    @extend %light-gray-text-mobile;
  }

  &__support_team_link {
    @extend %light-purple-link-mobile;
    font-size: 2.1vh !important;
  }

  &__privacy_policy_text {
    @extend %light-dark-text-mobile;
    text-align: center;
    width: 100%;
    margin-top: 9.851%;
  }

  &__privacy_policy_link {
    @extend %light-purple-link-mobile;
    font-size: 2.1vh !important;
  }

  &__button_container {
    display: flex;
    justify-content: center;
    margin-top: 5.3%;
  }

  &__button {
    height: 8.1vh !important;
    width: 57.59%;
  }
}
