@import '../../assets/';

.filter_tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.34% 1.34% 1.34% 2.4%;
  border-radius: 22px;
  background-color: $gray_17;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  &__text {
    @include span-mobile(400);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__close_button {
    width: 3vh;
    height: 3vh;
    margin-left: 1.3vw;
  }
}
