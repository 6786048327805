@import '../../assets/';

.filter_tags_container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 5.3%;
  margin-bottom: 8%;

  & > *:not(:first-child) {
    margin-left: 1.6%;
  }
}
