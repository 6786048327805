.wrapper {
  display: flex;
  flex-direction: column;

  &__block {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;

    &_item {
      animation-duration: 2500ms;
      animation-iteration-count: infinite;
      animation-name: flashing;
      animation-timing-function: ease-in-out;

      &:first-child {
        margin-right: 5px;
      }
    }

    &_item_first {
      animation-delay: 0ms;
    }

    &_item_second {
      animation-delay: 250ms;
    }

    &_item_third {
      animation-delay: 500ms;
    }

    &_item_fourth {
      animation-delay: 750ms;
    }

    @keyframes flashing {
      0% {
        opacity: 1;
      }

      20% {
        opacity: 0;
      }

      40% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 1;
      }
    }
  }
}