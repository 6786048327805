@import "../../../../assets";

.error_wrapper {
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  border-radius: 12px;
  margin-bottom: 40px;
}

.error_container {
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 20px 33px;
}

.error_container_icon {
  width: 26px;
  height: 23px;
  margin: 5px 13px 0 0;
}

.error_title {
  @extend %error-message-title;
  margin-bottom: 5px;
}

.error_description {
  @extend %error-message-text;
}

.error_description_link {
  text-decoration: underline;
}

.error_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.1%;
  background-color: $red_3;
  border-radius: 0 12px 12px 0;
}

.error_button_link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.error_button_icon {
  width: 18px;
  height: 14px;
  margin-left: 14px;
}

.error_button_text {
  @extend %error-message-button;
  text-decoration: underline;
}
