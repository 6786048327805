@import '../../../assets/index.scss';

.container {
  height: 100%;

  &__center {
    height: 69.1%;
    padding: 0 5.3%;
    text-align: center;
  }

  &__top_img {
    width: 17.1vw;
    height: 17.1vw;
  }

  &__title {
    @extend %main-text-h4-mobile;
    margin: 7.16% 0 2.99%;
  }

  &__text {
    @extend %light-dark-text-mobile;
  }

  &__bottom {
    height: 12.9%;
    display: flex;
    justify-content: center;

    button {
      height: 8.1vh !important;
      width: 51.5%;
    }
  }
}
