@import '../../../assets/index.scss';

.background {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(33, 36, 43, 0.7);
  height: 100vh;
  width: 100%;
  z-index: 2;
}

.content {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  position: fixed;
  flex-direction: column;
  max-width: 315px;
  width: 84%;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  @extend %main-text-h4;
  padding: 30px 23px;
  @include backgroundBlock($yellow, 8px);
  background: $white;
  z-index: 2;

  &__text {
    @include h4();

    &--title {
      @include h4(700);
    }
  }

  &__message {
    @include h4();
    margin-top: 20px;
  }

  &__button {
    margin-top: 30px;
  }

  &__button + &__button {
    margin-top: 10px;
  }

  &--mobile {
    padding: 8% 6.13%;
    max-width: initial;

    .content__text {
      @include h4-mobile();

      &--title {
        @include h4-mobile(700);
      }
    }

    .content__message {
      @include h4-mobile();
      margin-top: 7.44%;
    }

    .content__button {
      button {
        height: 6.6vh !important;
        font-size: 2.7vh !important;
      }
    }

    .content__button {
      margin-top: 11.09%;
    }
  
    .content__button  + .content__button  {
      margin-top: 3.72%;
    }
  }
}
