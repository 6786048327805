@import '../../../assets/index.scss';

.container {
  height: 100%;

  &__content {
    text-align: center;
  }

  &__title {
    line-height: 128%;
    font-weight: bold;
    color: $mainBlack;
  }

  &__block {
    @include backgroundBlock($mainYellow, 8px);
    display: flex;
    flex-direction: column;
    background-color: $white;
    text-align: left;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dashed $mainBlack;

      &_bankname {
        line-height: 128%;
        color: $mainBlack;
      }

      &_nickname {
        color: $gray_3;
        line-height: 130%;
        opacity: 0.6;
      }

      &_logo {
        img {
          width: 100%;
        }
      }
    }

    &__section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1.64%;

      p {
        line-height: 125%;
        color: $mainBlack;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;

    button {
      width: 51.5%;
    }
  }
}

.container__block__header.container__block__header__small {
  .container__block__header_bankname {
    @extend %black-text-mobile;
  }

  .container__block__header_nickname {
    @include span-mobile();
    color: $gray_3;
  }
}