@import '../../../assets';

.link_account_error {
  position: absolute;
  top: 107px;
  left: 50%;
  transform: translateX(-50%);
  height: fit-content;
  width: 51.228vh;

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4.0555vh 7.6841vh 3.2017vh;
    text-align: center;
  }

  &__cross_icon {
    width: 5vh;
    height: 5vh;
  }

  &__title {
    @extend %main-text-h4;
    font-size: 1.93vh !important;
    margin-top: 2vh;
    line-height: 128%;
  }

  &__error_text {
    @extend %light-dark-text;
    margin-top: 1vh;
  }

  &__inputs {
    margin-top: 2vh;
    width: 100%;

    & > *:last-child {
      margin-top: 3.2017vh;
    }

    label {
      font-size: 1.7vh !important;
    }

    input {
      font-size: 1.93vh !important;
      line-height: 128%;
    }
  }

  &__support_team_text {
    @extend %light-gray-text;
    font-size: 1.7vh !important;
    margin-top: 2vh;
    text-align: left;
  }

  &__support_team_link {
    @extend %light-purple-link;
    margin-top: 2vh;
    font-size: 1.7vh !important;
  }

  &__privacy_policy_text {
    @extend %light-dark-text;
    font-size: 1.7vh !important;
    margin-top: 1.8143vh;
  }

  &__privacy_policy_link {
    @extend %light-purple-link;
    font-size: 1.7vh !important;
  }

  &__button {
    height: 5.337vh !important;
    margin-top: 2.1345vh;
  }
}
