@import "../../assets";

.dropdown {
  display: flex;

  &--with_top_label {
    flex-direction: column;
    align-items: flex-start;

    .dropdown__label {
      margin-bottom: 6px;
    }

    .dropdown__input_block {
      width: 100%;
    }

    .dropdown__input {
      width: 100%;
    }
  }

  &--with_left_label {
    align-items: center;

    .dropdown__label {
      align-items: center;
      margin-right: 10px;
    }
  }

  &__label {
    @include span(700);
  }

  &__input_block {
    position: relative;
  }

  &__input {
    @include span();
    min-width: 224px;
    padding: 14px 10px 9px;
    background: $white;
    border: none;
    border-bottom: 1px solid rgba($gray, 0.6);

    &:focus {
      outline: 1px solid $mainYellow;
    }
  }

  &__input_icon {
    position: absolute;
    top: 14px;
    right: 10px;
    transform: rotateZ(-90deg);
  }

  &__input:focus + &__list,
  &__list:active {
    display: block;
  }

  &__list {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transform: translateY(100%);
    width: 100%;
    padding: 6px 0;
    background-color: $white;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 4px 10px rgba(60, 67, 77, 0.05), 0px 2px 30px rgba(60, 67, 77, 0.1);
  }

  &__list_item {
    @include main-tex-h5();
    cursor: pointer;
    padding: 11px 10px;

    &:hover {
      background-color: $mainBackground;
    }
  }

  &--mobile {
    .dropdown__label {
      @include span-mobile(700);
    }

    .dropdown__input_block {
      flex: 1;
    }

    .dropdown__input {
      @include span-mobile();
      min-width: initial;
      width: 100%;
      padding: 2.1vh 1.5vh 1.5vh;
    }

    .dropdown__input_icon {
      top: 50%;
      right: 3%;
      transform: translateY(-50%);
      width: 1.8vh;
      height: 1.8vh;
    }

    .dropdown__input:focus + .dropdown__list + .dropdown__input_icon {
      transform: rotateZ(-90deg) translateX(50%);
    }

    .dropdown__list {
      max-height: 30vh;
      padding: 1.8% 0;
    }

    .dropdown__list_item {
      @include main-tex-h5-mobile();
      padding: 3.3% 3%;
    }
  }
}

.dropdown--mobile.dropdown--with_left_label {
  margin-right: 2.99%;
}

.dropdown--mobile.dropdown--with_top_label {
  .dropdown__label {
    margin-bottom: 1.8% !important;
  }
}
