@import '../../../assets/index.scss';

::-webkit-scrollbar {
  display: none;
}

.cc {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;

  &__block {
    background-color: $white;
    @include size(514px, auto);
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid $border;
    padding: 24px 32px;
    margin-left: auto;
    margin-right: auto;

    &:first-child {
      margin-top: 5.177%;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 23px 0 16px;
      overflow: hidden;

      &_time {
        width: 30%;
        @extend %small-text;
        margin-right: 6%;
      }

      &_name {
        width: 33%;
        @extend %span;
        line-height: 15px;
      }

      &_url {
        width: 33%;
        @extend %grey-text;
      }
    }

    &__link {
      @extend %span;
      letter-spacing: 0.05em;
      text-decoration: underline;
      cursor: pointer;
    }

    &:first-child {
      padding: 24px 32px 10px;
    }

    &__account {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__wrapper {
        display: flex;
        align-items: center;
      }

      &__logo {
        width: 39px;
        height: 39px;
        margin-right: 27px;
      }

      &__button {
        width: 50%;
      }
    }

    iframe {
      width: 100%;
      min-height: 256px;
      border: none;
    }
  }
}
