@import './color.theme.scss';

@mixin family($_weight: normal, $_family: Mulish) {
  font-family: $_family;
  font-style: normal;
  font-weight: $_weight;
}

@mixin h4($_weight: normal) {
  @include family($_weight);
  font-size: 18px;
  line-height: 23px;
  color: $mainBlack;
}

@mixin h4-light-dark($_weight: normal) {
  @include h4($_weight);
  color: $lightDark !important;
}

@mixin main-tex-h5($_weight: normal) {
  @include family($_weight);
  font-size: 13px;
  line-height: 140%;
  color: $mainBlack;
}

@mixin body($_weight: normal) {
  @include family($_weight);
  font-size: 15px;
  line-height: 140%;
  color: $mainBlack;
}

@mixin span($_weight: normal) {
  @include family($_weight);
  font-size: 12px;
  color: $mainBlack;
}

@mixin span-black1($_weight: normal) {
  @include family($_weight);
  font-size: 12px;
  color: $black1;
}

%span {
  @include span;
}

%title-h3 {
  @include family;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: $mainBlack;
}

%title-h2 {
  @include family(600);
  font-size: 22px;
  line-height: 28px;
  color: $mainBlack;
}

%main-text-h4 {
  @include family(bold);
  font-size: 18px;
  line-height: 23px;
  color: $mainBlack;
}

%main-text-small {
  font-size: 16px;
  line-height: 20px;
  color: $mainBlack;
}

%total-amount {
  @include family(normal);
  font-size: 28px;
  line-height: 110%;
  color: $black1;
}

%small-text {
  font-size: 10px;
  line-height: 130%;
  color: $lightDark;
  opacity: 0.6;
}

%grey-text {
  font-size: 14px;
  line-height: 130%;
  color: $gray_3;
}

%black-text {
  @include family();
  font-size: 14px;
  line-height: 130%;
  color: $mainBlack;
}

%red-text {
  font-size: 14px;
  line-height: 130%;
  color: $red;
}

%light-dark-text {
  font-size: 14px;
  line-height: 130%;
  color: $lightDark;
}

%light-gray-text {
  font-size: 14px;
  line-height: 130%;
  color: $lightGray;
}

%consent-aisp-text {
  font-size: 14px;
  line-height: 130%;
  color: $gray_15; 
}

%title-h2 {
  @include family(bold);
  font-size: 22px;
  line-height: 28px;
  color: $mainBlack;
}

%toast-text {
  @include family();
  font-size: 16px;
  line-height: 22px;
  color: $white;
}

%link-text {
  @include family();
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: $textFieldBase;
}

%title-h1 {
  @include family(bold, Mulish);
  font-size: 36px;
  line-height: 120%;
  color: $mainBlack;
}

%h2 {
  @include family(bold, Mulish);
  font-size: 24px;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: $mainBlack;
}

%subscription-card-title {
  @include family(900, Mulish);
  font-size: 21px;
  line-height: 130%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight:900;
}

%subscription-card-text {
  @include family(600, Nunito Sans);
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.05em;
}

%link {
  @include family();
  font-size: 18px;
  line-height: 23px;
  text-decoration-line: underline;
  color: $mainBlack;
}

%light-purple-link {
  @include family();
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  color: $lightPurple;
}

%api-key-table-gray-text {
  font-size: 14px;
  line-height: 130%;
  color: $gray;
}

%gift-aid-amount-card-value {
  @include family();
  font-size: 28px;
  line-height: 110%;
  color: $black1;
}

%payment-details-details-amount {
  @include family(400, Nunito Sans);
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $black1;
}

%requests-table-text {
  @include family();
  font-size: 13px;
  line-height: 140%;
  color: $gray_13;
}

%payment-request-review-title {
  @include family();
  font-size: 36px;
  line-height: 100%;
  color: $mainBlack;
  letter-spacing: 0.05em;
}

%payment-request-review-item-title {
  @include family(700);
  font-size: 12px;
  line-height: 15px;
  color: $mainBlack;
  letter-spacing: 0.05em;
}

%fee-info-modal-title {
  @include family(700);
  font-size: 12px;
  line-height: 15px;
  color: $white;
}

%fee-info-modal-text {
  @include family(400);
  font-size: 12px;
  line-height: 18px;
  color: $white;
}

%error-message-title {
  @include family(700);
  font-size: 15px;
  line-height: 140%;
  color: $red;
}

%error-message-text {
  @include family(400);
  font-size: 13px;
  line-height: 140%;
  color: $red;
}

%error-message-button {
  @include family(400);
  font-size: 15px;
  line-height: 140%;
  color: $red;
}

%daypicker_weekday {
  @include family(400);
  font-size: 12px;
  line-height: 24px;
  color: $gray_13;
}