@import '../../../assets';

.link_account {
  height: 100%;

  &__content {
    text-align: center;
    height: 72%;
    padding: 0 5.334%;
  }

  &__cross_icon {
    width: 9.6vh;
    height: 9.6vh;
  }

  &__title {
    @include h4-mobile(700);
    margin: 7.165% 0 2.986%;
  }

  &__error_text {
    @extend %light-dark-text-mobile;
  }

  &__inputs {
    padding: 8.956% 0 2.39%;

    & > *:last-child {
      margin-top: 5.971%;
    }
  }

  &__support_team_text {
    @extend %light-gray-text-mobile;
    text-align: left;
  }

  &__support_team_link {
    @extend %light-purple-link-mobile;
    font-size: 2.1vh !important;
  }

  &__privacy_policy_text {
    @extend %light-dark-text-mobile;
    text-align: center;
    width: 100%;
    margin-top: 5.075%;
  }

  &__privacy_policy_link {
    @extend %light-purple-link-mobile;
    font-size: 2.1vh !important;
  }

  &__button_container {
    display: flex;
    justify-content: center;
    margin-top: 5.971%;
  }

  &__button {
    height: 8.1vh !important;
    width: 57.59%;
  }
}
