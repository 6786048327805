@import "../../assets/";

.popup_list {
  padding: 6px 0;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 4px 10px rgba(60, 67, 77, 0.05), 0px 2px 30px rgba(60, 67, 77, 0.1);

  &__item {
    display: flex;
    align-items: center;
    min-width: 160px;
    padding: 12px 10px;
    cursor: pointer;

    &:hover {
      background-color: $mainBackground;
    }

    &_icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    &_title {
      @include main-tex-h5();
    }
  }

  &--mobile {
    padding: 1.7% 0;

    .popup_list__item {
      min-width: 42.7vw;
      padding: 7.5% 6.25%;;

      &_icon {
        width: 2.4vh;
        height: 2.4vh;
        min-width: 12px;
        min-height: 12px;
        margin-right: 3.57%;
      }
    }
  }
}
