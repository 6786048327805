@import '../../../assets/index.scss';

.container {
  position: fixed;
  height: fit-content;
  left: 50%;
  top: 50vh;
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transform: translate(-50%, -50%);
  background-color: $white;
  box-shadow: 0px 4px 10px rgba(33, 36, 43, 0.05), 0px 2px 30px rgba(237, 187, 31, 0.1);
  @include size(480px, 541px);
  padding: 38px 73px 30px;

  &__title {
    @extend %main-text-h4;
  }

  &__block {
    width: 100%;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 25px;
    @include backgroundBlock($mainYellow, 8px);

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dashed #21242B;
      padding: 0 5px 15px;
      margin-bottom: 14px;

      &_bankname {
        @extend %main-text-h4;
        @include family(normal);
        margin-bottom: 2px;
      }

      &_nickname {
        @extend %grey-text;
      }

      &_logo {
        height: 40px;
        width: 40px;
        padding: 5px;
        box-sizing: border-box;

          img {
            width: 30px;
            height: 30px;
          }
      }
    }

    &__section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;

      &:last-child {
        margin-top: 6px;
      }

      p {
        @extend %main-text-small;
      }
    }
  }

  &__button {
    width: 85%;
    margin: 0 auto;
  }
}

.container__block__header.container__block__header__small {
  .container__block__header_bankname {
    @extend %black-text;
  }

  .container__block__header_nickname {
    @extend %span;
    color: $gray_3;
  }
}