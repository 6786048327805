@import "../../../assets";

.gift_aid {
  height: 100%;
  padding: 0 20px 24px;
  overflow-y: auto;
  position: relative;

  &__filters {
    display: flex;
    align-items: center;
  }

  &__search_container {
    width: 291px;
    margin-right: auto;
  }

  &__date_range_wrap {
    margin-right: 15px;
  }

  &__cards {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;

    & > * {
      flex: 1;
      max-width: 352px;
    }

    & > *:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.amount_card {
  display: flex;
  padding: 10px;
  border-radius: 12px;
  background: $white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  &__right_side {
    display: flex;
    flex-direction: column;
  }

  &__value {
    @extend %gift-aid-amount-card-value;
    margin-bottom: 2px;
  }

  &__description {
    @include main-tex-h5();
    opacity: 0.6;
  }
}

.table_container {
  display: block;
  white-space: nowrap;
  margin-top: 20px;
  width: 100%;

  &__table {
    padding: 30px 20px 10px;
    width: 100%;
    table-layout: fixed;
    text-align: left;
  }

  &__head {
    width: 100%;
    margin-bottom: 10px;
  }

  &__row {
    height: 50px;
    &:nth-child(odd) {
      background: rgba($gray, 0.05);
    }
  }

  &__row_value {
    @include main-tex-h5();
    opacity: 0.6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      margin-right: 20px;
    }

    &:not(:last-child) {
      padding-right: 5px;
    }
  }
}

.tWrapper {
  &__title {
    position: relative;
    padding-bottom: 10px;
    @include span(bold);
    cursor: pointer;

    &_icon {
      margin-left: 3px;
      vertical-align: middle;
    }

    &:first-child {
      width: 16.5%;
      padding-left: 97px;
    }

    &:nth-child(2) {
      width: 12.5%;
    }

    &:nth-child(3) {
      width: 9%;
    }

    &:nth-child(4) {
      width: 19.5%;
    }

    &:nth-child(5) {
      width: 11.5%;
    }

    &:nth-child(6) {
      width: 17.5%;
    }

    &:last-child {
      width: 5.5%;
      padding-right: 4px;
    }

    &::after {
      content: '';
      display: inline-block;
      @include size(12px);
      margin-left: 2px;
      background: center / cover no-repeat;
    }

    &--asc {
      &::after {
        transform: rotate(270deg);
        background-image: url(../../../assets/images/backspace-arrow-16px.svg);
      }
    }

    &--desc {
      &::after {
        transform: rotate(90deg);
        background-image: url(../../../assets/images/backspace-arrow-16px.svg);
      }
    }

    &--inactive {
      &::after {
        transform: rotate(90deg);
        background-image: url(../../../assets/images/backspace-arrow-disabled.svg);
      }
    }
  }
}