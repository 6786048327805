@import "../../assets/index.scss";

.formInput {
  display: flex;
  flex-direction: column;

  &__field {
    @extend %black-text;
    color: #000;
    line-height: 24px;
    padding: 17px 12px;
    border: 1px solid $gray_3;
    border-radius: 4px;
    &--error {
      border-color: $lightRed;
    }
    &--mobile {
      @extend %black-text-mobile;
      padding: 6% 4%;
    }
  }

  &__label {
    @extend %grey-text;
    color: $grayAa;
    margin-bottom: 5px;

    &--required {
      &::after {
        content: "*";
        color: $lightRed;
      }
    }

    &--mobile {
      @extend %grey-text-mobile;
      margin-bottom: 1.5%;
    }
  }

  &__info {
    margin-top: 2px;
    color: $gray_3;
    font-size: 13px;
    line-height: 24px;

    &--mobile {
      margin-top: 0.7%;
      font-size: 1.9vh;
      line-height: 185%;
    }
  }

  &__error {
    @include body(normal);
    color: $lightRed;
    margin-top: 7px;

    &--mobile {
      @include body-mobile(normal);
      color: $lightRed;
      margin-top: 2%;
    }
  }
}
