@import '../../../assets/index.scss';

.container {
  position: fixed;
  height: fit-content;
  left: 50%;
  top: 50vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  transform: translate(-50%, -50%);
  background-color: $white;
  box-shadow: 0px 4px 10px rgba(33, 36, 43, 0.05),
    0px 2px 30px rgba(237, 187, 31, 0.1);
  @include size(480px, 541px);
  padding: 39px 73px 18px;

  &__title {
    margin: 24px 0 10px;
    @extend %main-text-h4;
  }

  &__link {
    @extend %black-text;
    display: inline-block;
    color: $lightPurple;
    text-decoration: underline;
    margin: 10px 0 12px;
  }

  &__text {
    @extend %light-dark-text;
  }

  &__button {
    width: 85%;
    margin: 0 auto;

    a {
      @extend %black-text;
      color: $orange;
      display: inline-block;
      letter-spacing: 0.05em;
      margin-top: 25px;
    }
  }
}
