@import '../../assets/';

.datePicker {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: $white;
  box-shadow: 0px 4px 10px rgba(60, 67, 77, 0.05), 0px 2px 30px rgba(60, 67, 77, 0.1);
  border-radius: 0px 0px 4px 4px;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4.8% 3% 5.99%;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      @include size(100%, 1px);
      background-color: $gray_12;
    }

    & > .datePicker__wrapper {
      width: 38%;
      &:not(:first-child) {
        margin-right: 3.3%;
      }
    }

    & > span {
      @include span-mobile;
      color: $gray;
      margin: 0 1%;
      align-self: center;
      letter-spacing: 0.05em;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__date_preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13.4% 8.4%;
    border: 1px solid $gray_14;
    border-radius: 8px;

    &--active {
      border-color: $gray;
    }

    & > input[type=date] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      &::-webkit-datetime-edit-fields-wrapper,
      &::-webkit-date-and-time-value {
        display: none;
      }

      &::-webkit-calendar-picker-indicator {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      &:focus {
        outline: none;
      }
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
    }

    &_value {
      @extend %black-text-mobile;
    }
  }


  &__button {
    @extend %black-text-mobile;
    font-weight: 700;
    width: 16.5%;
    height: 6vh !important;
    cursor: pointer;
  }

  &__list {
    padding-bottom: 1.8%;
  }

  &__listItem {
    @include body-mobile;
    display: flex;
    align-items: center;
    height: 6vh;
    padding-left: 3%;
    color: $gray;
  }

  &__daypickerStartWrap {
    position: absolute;
    left: 0;
    width: 85vw;
  }

  &__daypickerEndWrap {
    position: absolute;
    right: 0;
    width: 85vw;

    & > * {
      right: 0;
    }
  }
}
