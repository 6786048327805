@import '../../assets/index.scss';

.mWrapper {
  &__menu {
    display: flex;
    flex-direction: column;
    padding-bottom: 10%;
    @include size(220px, 100%);
    background: rgba(255,255,255,0.2);
  }

  &__header {
    display: flex;
    height: 78px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    background: $white;
    border-bottom: 3px solid $mainBlack;
  }

  &__header_close_icon {
    display: none;
  }

  &__logo {
    @include size(111px, 56px);
    cursor: pointer;
  }

  &__list {
    &:first-of-type {
      flex: 1;
      margin-top: 31px;
    }

    &:last-child {
      margin-bottom: 26px;
    }

    & > :not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &--mobile {
    height: 100%;

    .mWrapper__menu {
      width: 100%;
      background: top / cover no-repeat url(../../assets/images/backgrounds/single-bg-img.jpg);
    }

    .mWrapper__header {
      position: relative;
      height: 11.7vh;
    }

    .mWrapper__header_close_icon {
      display: inline;
      position: absolute;
      top: 34.7%;
      right: 5.48%;
      width: 3.6vh;
      height: 3.6vh;
    }

    .mWrapper__logo {
      width: 16.65vh;
      height: 8.4vh;
    }

    .mWrapper__list {
      &:first-of-type {
        flex: 1;
      }

      &:last-child {
        margin-bottom: 5.8%;
      }

      & > :not(:last-child) {
        margin-bottom: 0.5vh;
      }
    }

    .mWrapper__list_bottom {
      & > :not(:last-child) {
        margin-bottom: 0.4vh;
      }
    }
  }
}

.mItem {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 20px;
  cursor: pointer;

  &:only-child {
    padding-left: 13px;
  }

  &--active {
    background: $mainYellow;
  }

  &__iWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    @include size(24px);
    margin-right: 11px;
  }

  &__title {
    @include span(600);
    text-transform: capitalize;
    white-space: nowrap;
  }

  &--mobile {
    height: 6vh;
    padding-left: 6.85%;

    img {
      width: 3.6vh;
      height: 3.6vh;
    }

    &:only-child {
      padding-left: 7.2%;

      img {
        width: 4.2vh;
        height: 2.7vh;
      }
    }

    .mItem__iWrapper {
      justify-content: flex-start;
      height: auto;
      width: auto;
      margin-right: 5.51%;
    }

    .mItem__title {
      font-weight: 600 !important;
      line-height: 185% !important;
      color: #21242b;
    }
  }
}
