@import '../../assets/index.scss';

.bank_item {
  height: 7.8vh;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 7.8vh;
  border: 1px solid $gray_18;
  overflow: hidden;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    overflow: visible;
    padding: 1.2vh;
    background: $mainBlack;
    border-radius: 7.5vh;

    img {
      width: 5.1vh;
    }

  }

  &__background_white {
    background: $white;
    padding: 0;
    width: 7.5vh;
    height: 7.5vh;

    img {
      width: 7.5vh;
      border-radius: 7.5vh;
    }
  }

  &__account {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $mainBlack;
    padding: 0 3.02%;

    &__name {
      @extend %main-text-h4-mobile;
      text-align: left;
    }
  }
}

.bank_item__account__red_text {
  @extend %red-text-mobile;
  font-weight: normal !important;
  display: block;
}

.bank_item__grey {
  .bank_item__account__name {
    color: $gray_5;
  }
}
