@import '../../../assets/index.scss';

.activity {
  height: 100%;
  padding: 0 20px 40px 20px;
  position: relative;
  overflow-y: auto;

  &__input_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__search_wrapper {
    width: 23%;
  }
  &__date_wrapper {
    display: flex;
    justify-content: flex-start;
  }

  &__reference_item {
    padding: 5px 0;
    cursor: pointer;
  }

  &__dateRange_input {
    width: 16vw;

    &_items {
      @include body(normal);
      position: absolute;
      list-style-type: none;
      top: 15.1%;
      right: 21px;
      width: 16vw;
      background: $white;
      padding: 5px 7px;
    }
  }

  &__content {
    display: flex;
  }

  &__main {
    flex: 1;
    margin-right: 11px;
  }

  &__pagination {
    margin-top: 30px;
  }
}

.tWrapper {
  display: block;
  padding: 30px 10px 0px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &_withoutItems {
    height: 653px;
    background-color: $white;
    border-radius: 12px;
  }

  &_emptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100%;

    &_icon {
      max-height: 88px;
      max-width: 101px;
    }

    &_subtitle {
      margin-top: 30px;
      color: #BCBDBF;
    }
  }

  &__tools {
    display: flex;
    // flex-shrink: 1;
    margin-bottom: 35px;

    & > :not(:last-child) {
      margin-right: 20px;
      flex: 1;
    }
  }

  &__filterMenuIcon {
    display: none;
  }

  &__amountRange {
    display: flex;
    align-items: flex-end;
    margin-right: 7%;

    & > :not(span) {
      max-width: 114px;
    }

    & > span {
      margin: 0 2px 10px;
      @include span;
      color: $gray;
      letter-spacing: 0.05em;
    }
  }

  &__table {
    width: 100%;
    table-layout: fixed;
    text-align: left;

    &_head {
      position: relative;
    }
  }

  &__row {
    text-overflow: ellipsis;
    padding: 10px;
  }

  &__title {
    text-overflow: ellipsis;
    position: relative;
    padding-bottom: 10px;
    @include span(bold);
    cursor: pointer;

    &_icon {
      margin-left: 3px;
      vertical-align: middle;
    }

    //icon
    &:first-child {
      width: 60px;
    }

    //date
    &:nth-child(2) {
      width: 18ch;
    }

    //id
    &:nth-child(3) {
      width: 11%;
    }

    //reference
    &:nth-child(4) {
      width: 18ch;
    }

    //website
    &:nth-child(5) {
      width: 14%;
    }
    //email
    &:nth-child(6) {
      width: 14%;
    }
    //notes
    //&:nth-child(7) {
    //  min-width: 15ch;
    //}
    //amount
    &:last-child {
      width: 10ch;
      padding-right: 4px;
    }

    &::after {
      content: '';
      display: inline-block;
      @include size(12px);
      margin-left: 2px;
      background: center / cover no-repeat;
    }

    &--asc {
      &::after {
        transform: rotate(270deg);
        background-image: url(../../../assets/images/backspace-arrow-16px.svg);
      }
    }

    &--desc {
      &::after {
        transform: rotate(90deg);
        background-image: url(../../../assets/images/backspace-arrow-16px.svg);
      }
    }

    &--inactive {
      &::after {
        transform: rotate(90deg);
        background-image: url(../../../assets/images/backspace-arrow-disabled.svg);
      }
    }
  }
}

.tableRow {
  & > * {
    height: 60px;
    padding-right: 20px;
    @include main-tex-h5;
    text-overflow: ellipsis;
  }

  &:nth-of-type(2n - 1) {
    background: rgba(151, 164, 186, 0.05);
  }

  &__value {
    text-overflow: ellipsis;
    @include main-tex-h5(400);
    color: rgba($mainBlack, 0.6) !important;

    &:first-child {
      display: flex;
      align-items: center;
      padding-left: 8px;
    }
  }

  &__value-wrap {
    display: flex;
    align-items: center;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__icon {
    margin-right: 3px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__stepBtn {
    display: flex;
    height: 100%;
    background-color: transparent;

    @include span;
    text-transform: uppercase;

    border: none;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      color: $gray_4;
    }

    & img {
      margin-left: 2px;

      &:first-child {
        margin-left: 0;
        margin-right: 2px;
        transform: rotate(180deg);
      }
    }
  }

  &__pages {
    display: flex;
    margin: 0 33px;
  }

  &__numBtm {
    display: flex;
    @include size(30px);
    justify-content: center;
    align-items: center;

    @include span(bold);
    color: $white;
    letter-spacing: 0.05em;
    background: $lightDark;

    cursor: pointer;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &--active {
      color: $mainBlack;
      background: $mainYellow;
      pointer-events: none;
    }

    &--split {
      pointer-events: none;
    }
  }
}