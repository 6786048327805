@import '../../../assets';

.request_activity {
  padding: 0 20px 15px;

  &__filters {
    display: flex;
    align-items: center;
    padding: 0 0 20px;
  }

  &__filters_right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__search_filter_wrap {
    input {
      width: 15.16vw;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: 788px;
  }

  &__pagination {
    margin-top: 30px;
  }
}

.table {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 30px 20px 0;
  white-space: nowrap;

  table {
    width: 100%;
    text-align: left;
    table-layout: auto;
    white-space: nowrap;
  }

  th {
    padding-bottom: 10px;
  }

  &__title {
    @include span(700);
    position: relative;
    cursor: pointer;

    &_inner {
      display: flex;
      align-items: center;

      &::after {
        @include size(12px);
        content: '';
        display: inline-block;
        margin-left: 2px;
        margin-bottom: -1px;
        background: center / cover no-repeat;
      }
  
      &--asc {
        &::after {
          transform: rotate(270deg);
          background-image: url(../../../assets/images/backspace-arrow-16px.svg);
        }
      }
  
      &--desc {
        &::after {
          transform: rotate(90deg);
          background-image: url(../../../assets/images/backspace-arrow-16px.svg);
        }
      }
  
      &--inactive {
        &::after {
          transform: rotate(90deg);
          background-image: url(../../../assets/images/backspace-arrow-disabled.svg);
        }
      }
    }
  }
}

.table_row {
  position: relative;
  height: 60px;

  &:nth-child(odd) {
    background-color: rgba($gray, 0.05);
  }

  td {
    //status icon
    &:first-child {
      width: 5%;
      padding-left: 30px;
    }

    &:not(:last-child) {
      padding-right: 5px;
    }

    //date
    &:nth-child(2) {
      width: 8%;
    }

    // request id
    &:nth-child(3) {
      width: 15%;
    }

    //reference
    &:nth-child(4) {
      width: 10%;
    }

    //website url
    &:nth-child(5) {
      width: 12.5%;
    }

    //payer email
    &:nth-child(6) {
      width: 12.5%;
    }

    //type
    &:nth-child(7) {
      width: 12.5%;
    }

    //amount
    &:nth-child(8) {
      width: 12.5%;
    }

    //dots
    &:nth-child(9) {
      width: 12.5%;
    }
  }

  &__value {
    @extend %requests-table-text;

    &--status-open,
    &--status-closed {
      @extend %requests-table-text;
      padding: 3px;
      border-radius: 3px;
    }

    &--status-open {
      color: $mainBlack !important;
      background-color: $mainYellow;
    }

    &--status-closed {
      background-color: $gray_14;
    }
  }

  &__three_dots {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__popup {
    position: absolute;
    right: -3px;
    top: 42px;
    z-index: 1;
  }
}

.date_filter {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 12px;

  &__label {
    @include span-black1(700);
  }

  input {
    min-width: 224px;
    margin-left: 10px;
  }
}

.content {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  position: fixed;
  flex-direction: column;
  max-width: 315px;
  width: 84%;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  @extend %main-text-h4;
  padding: 30px 23px;
  @include backgroundBlock($yellow, 8px);
  background: $white;
  z-index: 2;
}