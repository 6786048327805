@import '../../../assets/index.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 28px;

  &__consent_expire {
    border-radius: 7px;
    background-color: rgba(33,36,43, 0.05);
    @extend %consent-aisp-text;
  }

  &__header {
    padding: 4.3% 5.3% 6.4%;
  }

  &__header_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10.5%;
  }

  &__title {
    color: $gray_10 !important;
    font-weight: 600 !important;
    line-height: 128% !important;
  }

  &__header_bottom {
    text-align: center;

    p {
      color: $lightDark !important;
      line-height: 128% !important;
    }
  }

  &__center {
    padding: 0 3.29% 0 5.67%;
    text-align: center;
  }

  &__details_block {
    @include backgroundBlock($mainYellow, 7px);
    line-height: 128% !important;
    color: $lightDark !important;
    border: 1px solid #707070;
    background: $white;
    text-align: left;
  }

  &__text_black {
    line-height: 130% !important;
    color: $lightDark !important;
    padding: 0 5.6%;

    a {
      color: $lightPurple;
      text-decoration: underline;
    }
  }

  &__text_black_bottom {
    padding: 0;
  }

  &__bottom {
    padding: 0 12.3%;
    text-align: center;
  }

  &__button_details {
    button {
      height: 8.1vh !important;
      width: 68.3% !important;
      margin: 0 auto;
    }
  }

  &__panel {
    display: flex;
    justify-content: center;
  }
}
