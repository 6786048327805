@import '../../../assets/';

.request_details {
  padding: 0 20px 32px;

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 408px;
    margin: 0 auto;
    padding: 30px 20px 40px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);

    &_close_icon {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
    }

    &_title {
      @extend %title-h3;
      margin-bottom: 40px;
    }

    &_info_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-bottom: 30px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba($gray_15, 0.15);
    }

    &_QR_container {
      margin-bottom: 30px;
    }

    &_amount {
      @extend %title-h1;
      margin-bottom: 10px;

      &--any {
        font-size: 24px !important;
        margin-bottom: 13px;
      }
    }

    &_reference {
      @include main-tex-h5();
    }

    &_hint {
      @include span();
      color: $gray_13 !important;
      text-align: center;
    }

    &_bottom {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0 16px;
      margin-top: 50px;
    }

    &_copy_button {
      height: 56px !important;
      width: 100%;
    }

    &_save_button {
      @include body();
      text-decoration: underline;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  &__popup {
    position: static;
    transform: none;
    margin-bottom: 10px;
    margin-top: -58px;
  }
}
