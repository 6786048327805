@import '../../assets/index.scss';

.total {
  &__block {
    display: flex;
    justify-content: flex-start;
    padding: 30px 0 20px 0;
  }

  & > img {
    width: 30px;
    height: 30px;
  }

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0 10px 10px;
    width: 28%;
    background: $white;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    border-radius: 12px;

    &_text_block {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    &_value {
      @extend %total-amount;
    }

    &_title {
      @extend %grey-text;
    }
  }

  &__wrapper:first-child {
    margin-right: 20px;
  }
}