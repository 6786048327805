@import '../../../assets/';

.too_many_attempts {
  @include size(480px, 541px);
  position: fixed;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;

  &__inner {
    position: relative;
    padding: 97px 90px 32px;
    width: 100%;
    height: 100%;
  }

  &__icon {
    width: 64px;
    height: 64px;
  }

  &__title {
    @extend %title-h2;
    text-transform: uppercase;
    margin: 24px 0 36px;
  }

  &__text {
    @include h4();
  }

  &__countdown {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);

    span {
      @include h4(600);
      color: $red;
    }
  }
}
